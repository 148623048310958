import { ButtonWrapper } from "./Button.style";

export const ButtonBackgroundColorType = {
  Blue: "Blue",
  Grey: "Grey",
  Orange: "Orange",
  Transparent: "Transparent",
};

export type ButtonBackgroundColorType = (typeof ButtonBackgroundColorType)[keyof typeof ButtonBackgroundColorType];

interface Props {
  children?: React.ReactNode;
  bgColor: ButtonBackgroundColorType;
  blockButton: boolean;
  isChecked?: boolean;
  disabled?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClick?: () => void;
}

export default function Button({ children, bgColor, blockButton, isChecked = false, disabled = false, prefix = false, suffix = false, onClick }: Props) {
  return (
    <>
      <ButtonWrapper disabled={disabled} blockButton={blockButton} isChecked={isChecked} onClick={onClick} backgroundType={bgColor} bgColor={backgroundColor[bgColor]} borderStyle={borderStyle[bgColor]} color={color[bgColor]} borderRadius={borderRadius[bgColor]} hoverOutline={hoverOutline[bgColor]}>
        {prefix}
        {children}
        {suffix}
      </ButtonWrapper>
    </>
  );
}

const backgroundColor = {
  [ButtonBackgroundColorType.Blue]: "#E6F4F5",
  [ButtonBackgroundColorType.Grey]: "#CCC",
  [ButtonBackgroundColorType.Orange]: "#d76f2c",
  [ButtonBackgroundColorType.Transparent]: "#fff",
};

const hoverOutline = {
  [ButtonBackgroundColorType.Blue]: "2px solid #006571",
  [ButtonBackgroundColorType.Grey]: "none",
  [ButtonBackgroundColorType.Orange]: "none",
  [ButtonBackgroundColorType.Transparent]: "none",
};

const color = {
  [ButtonBackgroundColorType.Blue]: "#3a3a39",
  [ButtonBackgroundColorType.Grey]: "#fff",
  [ButtonBackgroundColorType.Orange]: "#fff",
  [ButtonBackgroundColorType.Transparent]: "#008E9F",
};

const borderRadius = {
  [ButtonBackgroundColorType.Blue]: "8px",
  [ButtonBackgroundColorType.Grey]: "38px",
  [ButtonBackgroundColorType.Orange]: "38px",
  [ButtonBackgroundColorType.Transparent]: "38px",
};

const borderStyle = {
  [ButtonBackgroundColorType.Blue]: "1px solid #C6E7E9",
  [ButtonBackgroundColorType.Grey]: "1px solid #ccc",
  [ButtonBackgroundColorType.Orange]: "1px solid #d76f2c",
  [ButtonBackgroundColorType.Transparent]: "2px solid #008E9F",
};
