import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useStore } from "../../../../store/store";
import { Body, Headline, Link, Paragraph, ParagraphContainer, SalaryCheckDiv, Wrapper } from "./ContactMeReciept.style";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

export default function ContactMeReciept() {
    const payload = useStore.usePayload();
    const WrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    
    useEffect(() => {
        scrollTo({ top: WrapperRef.current.offsetTop - 300, behavior: "smooth" })
    }, [WrapperRef])

    useEffect(() => {

    }, [payload.occupation])

    return (
        <Wrapper ref={WrapperRef}>
            <Body>
                <Headline>Dine oplysninger er sendt</Headline>
                {payload.occupation === "Student" && (
                    <ParagraphContainer>
                        <Paragraph>Vi ringer til dig hurtigst muligt for at blive klogere på dig og din uddannelse, og om vi er det rette match.<br/> Vi glæder os til at snakke med dig.</Paragraph>
                    </ParagraphContainer>
                )}
                {payload.occupation !== "Student" && (
                    <ParagraphContainer>
                        <Paragraph>Vi kontakter dig hurtigst muligt.<br/></Paragraph>
                        <SalaryCheckDiv><b>Har du prøvet et løntjek?</b> <br/>I Ases lønberegner kan du finde ud af, om du tjener nok, eller hvad du kan forvente at få i løn, hvis du skifter job eller er ny på arbejdsmarkedet.</SalaryCheckDiv>
                        <Link href="https://ase.dk/faa-svar/loen/loenberegner?step=1">Tjek din løn her</Link>
                    </ParagraphContainer>
                )}
                <Paragraph>Med venlig hilsen <br/> <b>Ase</b></Paragraph>
            </Body>
        </Wrapper>
    )
}