import { BiCheck } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

const getModalContentStyling = (modalBlock: boolean | undefined) => { 
  switch(modalBlock) {
    case true:
      return`
        margin: 30px auto 30px auto;
        padding: 20px;
        height: -webkit-fill-available;
        width: 90%;  
      `;
    case false: 
      return`
        margin: 100px auto 0px auto;
        width: 90%;  
        @media ${devices.tablet} {
          width: 70%;
        }
        @media ${devices.laptop} {
          width: 50%;
        }
        @media ${devices.laptopLdesktop} {
          width: 40%;
        }
      `;
  }
}

export const Container = styled.div<{ showModal: boolean, modalBlock: boolean }>`
  display: ${({ showModal }) => (!showModal ? "none" : "block")};
  position: fixed;
  z-index: 5000;
  padding-top: ${({ modalBlock}) => (modalBlock ? "30" : "100")}px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ modalBlock}) => (modalBlock ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.4)")};
  color: var(--color-coal);

`;

export const ModalContent = styled.div<{modalBlock: boolean | undefined}>`
  ${({ modalBlock }) => getModalContentStyling(modalBlock)};
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.36) 2px 2px 10px 5px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px 10px 0px 10px;
`;

export const CloseButton = styled.button`
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  background-color: var(--color-orange);
  &:hover {
    background-color: #c86728;
  }
`;

export const CloseIcon = styled(IoClose)`
  color: white;
`;

export const ModalBody = styled.div`
  padding: 0px 30px 30px 30px;
`;

export const Headline = styled.h1`
  font-weight: 600;
  text-transform: initial;
  font-size: 1.5em;
  margin-bottom: 30px;
`;
export const Content = styled.p`
  font-size: 1em;
  margin-bottom: 15px;
`;