import { useEffect, useMemo, useRef, useState } from "react";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import ProductCard from "../../components/ProductCard";
import { BottomContainer, Container, FirstRow, Headline, HeadlineSelfemployeed, MainContentWrapper, RecieptContainer, SalaryInsuranceInfo, SelfEmployeedContainer } from "./ProductStep.style";
import { useStore } from "../../../../store/store";
import ContactMeStep from "../ContactMeStep";
import { AccordionStateEnum } from "../../components/Accordion/Accordion";
import Reciept from "../../components/Reciept";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import InputVF from "../../components/InputVF";
import { InputTypeEnum } from "../../components/InputVF/InputVF";

export default function ProductStep() {
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const [showContactMe, setShowContactMe] = useState(false);
  const translations = useStore.useTranslations();
  const offerEnabled = useStore.useOfferEnabled();

  //Preeselcted products via productcard: If you go to OI via productcards, the choosen products is set in URL and they need to be preselected
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get("produkt");
    const productList = product?.split("|");
    
    if (product) {
      setPayload({
        wantsUnemploymentBenefits: false,
        wantsSalaryInsurance: false,
        wantsUnion: false,
        wantsHRJura: false,
        wantsBasic: false,
        wantsFamilyUnion: false,
      });
      
      if (productList?.includes("akasse")) {
        setPayload({
          wantsUnemploymentBenefits: true,
        });
      }
      
      if (productList?.includes("fagforening") && payload.occupation !== "SelfEmployed") {
        setPayload({
          wantsUnion: true,
        });
      }
      
      if (productList?.includes("hrjura") && payload.occupation === "SelfEmployed") {
        setPayload({
          wantsHRJura: true,
        });
      }
      
      if (productList?.includes("loensikring")) {
        setPayload({
          wantsSalaryInsurance: true,
        });
      }
      
      if (productList?.includes("basis") && payload.occupation === "SelfEmployed") {
        setPayload({
          wantsBasic: true,
        });
      }
      
      if (productList?.includes("familiefagforening") && (payload.occupation === "Employee" || payload.occupation === "Unemployed")) {
        setPayload({
          wantsFamilyUnion: true,
        });
      }
    }
  }, [payload.occupation]);
  //Preslected products end

  const setDiscount = () => {
    if (payload.wantsUnemploymentBenefits && payload.wantsUnion && offerEnabled) {
      setPayload({ combinationDiscount: true });
    } else if (payload.wantsUnemploymentBenefits && payload.wantsFamilyUnion && offerEnabled) {
      setPayload({ combinationDiscount: true });
    } else {
      setPayload({ combinationDiscount: false });
    }
  };
  
  const nextStepAllowed = useMemo(() => {
    if ((payload.occupation === "Employee" || payload.occupation === "Unemployed") && (payload.wantsUnemploymentBenefits || payload.wantsSalaryInsurance || payload.wantsUnion || payload.wantsFamilyUnion)) {
      return true;
    } else if (payload.occupation === "SelfEmployed") {
      if((payload.wantsUnemploymentBenefits || payload.wantsSalaryInsurance || payload.wantsBasic || payload.wantsHRJura)) {
        return true
        } 
        if((payload.wantsUnemploymentBenefits || payload.wantsSalaryInsurance || payload.wantsBasic || payload.wantsHRJura)) {
          return true
        }
        return false
      } else if (payload.occupation === "Student" && (payload.wantsUnemploymentBenefits || payload.wantsUnion)) {
        return true;
      } else {
        return false;
      }
    }, [payload]);

    //Tracking
    //Tracking of questions showed on next step - circumstanceStep
    const circumstanceTracking = () => { 
      const questions = [];
      
      questions.push("Arbejder du primært i DK")
      
      if (payload.wantsUnemploymentBenefits) {
        questions.push("Er du medlem af en akasse")
      }
      
      if (payload.wantsUnion || payload.wantsFamilyUnion) {
        questions.push("Er du medlem af en fagforening i dag")
      } 
      
      if (payload.wantsHRJura || payload.wantsBasic) {
        questions.push("Er du selvstændig på fuldtid")
      }
      
      Analytics.getInstance().trackCircumstanceStep(questions, "circumstance_questions_start");
    };

    
    const next = () => {
      if((payload.occupation === "Employee" || payload.occupation === "Unemployed") && payload.wantsFamilyUnion && payload.wantsSalaryInsurance) {
          setPayload({ cause: "Ønsker familiefagforening og lønsikring"});
      }

      if(payload.occupation === "SelfEmployed" && payload.doYouRunYourOwnBusiness === false) {
        setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
        setShowContactMe(true);
      } else if ((payload.occupation === "Employee" || payload.occupation === "Unemployed") && (payload.wantsSalaryInsurance || payload.wantsFamilyUnion) && !payload.wantsUnemploymentBenefits && !payload.wantsUnion) {
        if(payload.wantsFamilyUnion) {
          setPayload({ cause: "Kun familiefagforening" });
        } 
        if(payload.wantsSalaryInsurance) {
          setPayload({ cause: "Kun lønsikring" });
        } 
        setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
        setShowContactMe(true);
      } else if (payload.occupation === "SelfEmployed" && ((payload.wantsSalaryInsurance && !payload.wantsUnemploymentBenefits) || ((payload.wantsHRJura || payload.wantsBasic) && !payload.wantsUnemploymentBenefits))) {
        if (payload.wantsBasic) {
          setPayload({ cause: "Ønsker selvstændig forening" });
        }
        if (payload.wantsHRJura) {
          setPayload({ cause: "Ønsker Hr jura" });
        }
        if (payload.wantsSalaryInsurance) {
          setPayload({ cause: "Ønsker Lønsikring" });
        }
        setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
        setShowContactMe(true);
      } else if (payload.occupation === "Student" && payload.wantsUnemploymentBenefits) {
        setPayload({ cause: "Student" });
        setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
        setShowContactMe(true);
      } else {
        setAccordionRoute(AccordionRoutesEnum.CircumstanceStep, payload);
        circumstanceTracking()
      }
  };
  
  const back = () => {
    setAccordionRoute(AccordionRoutesEnum.ProductStep, payload);
    setShowContactMe(false);
  };

  return (
    <>
      <Accordion
        title="2. Valg af medlemskab"
        step={AccordionRoutesEnum.ProductStep}
        onNext={() => {
          // Add tracking
          Analytics.getInstance().trackProducts(payload, offerEnabled, translations, TrackingFlowTypeEnum.Vertical);
          setCompletedAccordionRoute(AccordionRoutesEnum.ProductStep);
          setDiscount();
          next();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        stepComplete={nextStepAllowed}
        previousStep={AccordionRoutesEnum.OccupationStep}
        headerImg="/assets/images/tdesign_member.svg"
      >
        <MainContentWrapper>
          {payload.occupation !== "Student" && <Headline>{payload.occupation === "SelfEmployed" ? "Til dig personligt" : "Vælg a-kasse og/eller lønsikring"}</Headline>}
          <FirstRow>
            <Container>
              <ProductCard 
                title={translations?.productsStep.unemploymentInsurance.title} 
                subtitle={payload.occupation === "SelfEmployed" ? `${translations?.productsStep.unemploymentInsurance.price_selfemployed} ${translations?.general.krPerMonth}` : `${translations?.productsStep.unemploymentInsurance.price} ${translations?.general.krPerMonth}`} 
                onClick={() => (
                  payload.wantsUnemploymentBenefits ? setPayload({ wantsUnemploymentBenefits: false }) : setPayload({ wantsUnemploymentBenefits: true })
                )} 
                isChecked={payload.wantsUnemploymentBenefits === true} 
                iconSrc={payload.wantsUnemploymentBenefits ? "/assets/images/agreement-white.svg" : "/assets/images/agreement.svg"}
              >
                Få ret til dagpenge, hvis du mister jobbet
              </ProductCard>
              {(payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "SelfEmployed") && (
                <ProductCard 
                  title={translations?.productsStep.salaryGuarantee.title} 
                  onClick={() => (payload.wantsSalaryInsurance ? setPayload({ wantsSalaryInsurance: false }) : setPayload({ wantsSalaryInsurance: true }))} 
                  isChecked={payload.wantsSalaryInsurance === true} 
                  iconSrc={payload.wantsSalaryInsurance ? "/assets/images/money-bag-white.svg" : "/assets/images/money-bag.svg"}>
                  Ekstra dækning oveni dine dagpenge
                </ProductCard>
              )}
            </Container>
            {payload.wantsSalaryInsurance && (
              <SalaryInsuranceInfo><b>Husk!</b> Tjener du mindre end 28.865 kr./md. før skat, kan du ikke tegne lønsikring, da du er dækket af dine dagpenge.</SalaryInsuranceInfo>
            )}
          </FirstRow>
        </MainContentWrapper>
        <MainContentWrapper>
          {payload.occupation !== "Student" && <Headline>{payload.occupation === "Employee" || payload.occupation === "Unemployed" ? "Vælg en Fagforening" : "Til din virksomhed"}</Headline>}
          <BottomContainer>
            {(payload.occupation === "Employee" || payload.occupation === "Unemployed") && (
              <ProductCard
                title={translations?.productsStep.union.title}
                subtitle={`${translations?.productsStep.union.price} ${translations?.general.krPerMonth}`}
                onClick={() => {
                  setPayload({ wantsUnion: !payload.wantsUnion, wantsFamilyUnion: false });
                }}
                isChecked={payload.wantsUnion === true}
                iconSrc={payload.wantsUnion ? "/assets/images/university-white.svg" : "/assets/images/university.svg"}
                hasDiscount={offerEnabled === true ? true : false}
                //opacity={payload.wantsFamilyUnion === true ? true : false}
              >
                <p>Få juridisk hjælp i dit arbejdsliv.</p>
                {offerEnabled &&  
                  <p>{translations?.productsStep.offerTitle}</p>
                }
              </ProductCard>
            )}
           {payload.occupation === "Student" && (
            <ProductCard
              title={translations?.productsStep.union.title}
              subtitle={`${translations?.productsStep.union.price} ${translations?.general.krPerMonth}`}
              onClick={() => {
                setPayload({ wantsUnion: !payload.wantsUnion, wantsFamilyUnion: false });
              }}
              isChecked={payload.wantsUnion === true}
              iconSrc={payload.wantsUnion ? "/assets/images/university-white.svg" : "/assets/images/university.svg"}
              hasDiscount={false}
              //opacity={payload.wantsFamilyUnion === true ? true : false}
            >
              <p>Få juridisk hjælp i dit arbejdsliv.</p>
            </ProductCard>
           )} 
            {(payload.occupation === "Employee" || payload.occupation === "Unemployed") && (
              <ProductCard
                title={translations?.productsStep.familyUnion.title}
                subtitle={`${translations?.productsStep.familyUnion.price} ${translations?.general.krPerMonth}`}
                onClick={() => {
                  setPayload({
                    wantsUnion: false,
                    wantsFamilyUnion: !payload.wantsFamilyUnion,
                  });
                }}
                isChecked={payload.wantsFamilyUnion === true}
                iconSrc={payload.wantsFamilyUnion ? "/assets/images/heart-check-white.svg" : "/assets/images/heart-check.svg"}
                hasDiscount={offerEnabled === true ? true : false}
                //opacity={payload.wantsUnion === true ? true : false}
              >
                <p>Få juridisk hjælp til hele familiens arbejdsliv</p>
                {offerEnabled &&  
                  <p>{translations?.productsStep.offerTitle}</p>
                }
              </ProductCard>
            )}
            {payload.occupation === "SelfEmployed" && (
              <>
                <ProductCard 
                  title={translations?.productsStep.basic.title} 
                  subtitle={`${translations?.productsStep.basic.price} ${translations?.general.krPerMonth}`} 
                  onClick={() => {
                    (payload.wantsBasic ? setPayload({ wantsBasic: false }) : setPayload({ wantsBasic: true }))
                  }} 
                  isChecked={payload.wantsBasic === true} 
                  iconSrc={payload.wantsBasic ? "/assets/images/briefcase-selvstandig-white.svg" : "/assets/images/briefcase-selvstandig.svg"}
                >
                  Et skræddersyet netværk af advokater, revisorer og IT-specialister med speciale i at hjælpe selvstændige.
                </ProductCard>
                <ProductCard 
                  title={translations?.productsStep.hrjura.title} 
                  subtitle={`${translations?.productsStep.hrjura.price} ${translations?.general.krPerMonth}`} 
                  onClick={() => {
                    (payload.wantsHRJura ? setPayload({ wantsHRJura: false }) : setPayload({ wantsHRJura: true }))
                  }} 
                  isChecked={payload.wantsHRJura === true} 
                  iconSrc={payload.wantsHRJura ? "/assets/images/court-law-jura-white.svg" : "/assets/images/court-law-jura.svg"}
                >
                  Få hjælp til personalejura og rekruttering.
                </ProductCard>
              </>
            )}
          </BottomContainer>
        </MainContentWrapper>
        <RecieptContainer>
          <Reciept basePrice={true} />
        </RecieptContainer>
      </Accordion>

      <ContactMeStep showContactMe={showContactMe} onClose={back} />
    </>
  );
}
