import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";
import { FaCheck } from "react-icons/fa";

export const Container = styled.div<{ opacity?: boolean }>`
  display: flex;  
  position: relative;
  flex: 0 1 100%;
  opacity: ${({ opacity }) => (opacity ? "48%" : "100%")}
`;

export const Card = styled.div<{ isChecked: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;  
  position: absoulute;
  top: 0;
  left: 0;
  background-color: ${({ isChecked }) => (isChecked ? "var(--color-blue-700)" : "var(--color-blue-50)")};
  color: ${({ isChecked }) => (isChecked ? "#fff" : "var(--color-coal)")};
  border-radius: 6px;
  border: 1px solid #c6e7e9;
  min-height: 90px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    outline: 2px solid var(--color-blue-700);
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  clear: both;
  padding: 10px;
  @media ${devices.laptop} {
    clear: none;
  }
`;

export const ContentContainer = styled.div`
  margin-left: 20px;
  flex: 2;
`;

export const DivBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const Subtitle = styled.p<{ isChecked: boolean }>`
  font-size: 14px;
  margin-bottom: 0px;
  margin-bottom: 8px;
  font-weight: 600;
  color: ${({ isChecked }) => (isChecked ? "#fff" : "var(--color-orange)")};
`;

export const Description = styled.p<{ hasDiscount: boolean }>`
  font-size: var(--font-size-small);
  margin-bottom: 0px;
  width: fit-content;

  p {
    margin-bottom: 2px;
  }
`;

export const ImageContainer = styled.div`
  width: 48px;
`;

export const Img = styled.img<{ isChecked: boolean }>`
  width: auto;
`;

export const Offer = styled.div`
  background-color: var(--color-orange);
  border-radius: 8px;
  font-size: var(--font-size-small);
  padding: 3px 5px;
  width: fit-content;
  color: #fff;
  position: absolute;
  top: -10px;
  right: 8px;
`;

export const CheckContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
`;

export const Unchecked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
`;

export const Checked = styled(FaCheck)`
  color: var(--color-orange);
  font-size: 0.7em;
`;
