"use client";
import SimpleModal from "../../components/SimpleModal";
import { useStore } from "../../store/store";
import { ErrorTitle, Header, HeaderMobilepay, HeaderSubtitle, HeaderSubtitleMP, HeaderTitle, LoadingWrapper, MobilepayImg, TraceWrapper, Wrapper } from "./TrackAndTrace.styles";
import { useEffect, useState } from "react";
import UnionFlow from "./components/UnionFlow";
import TrackTraceItem from "../../components/TrackTraceItem";
import SalaryInsuranceFlow from "./components/SalaryInsuranceFlow";
import { getTrackAndTraceEvents } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { TrackTracerItemActionsEnum } from "../../types/TrackTracerItemActionsEnum";

export default function TrackAndTrace() {
  const setTrackTraceResponse = useStore.useSetTrackTraceResponse();
  const response = useStore.useTrackTraceResponse();
  const setUnionFlowPayload = useStore.useSetUnionFlowPayload();
  const salaryInsuranceVisible = useStore.useSalaryInsuranceFlowVisible();
  const unionFlowVisible = useStore.useUnionFlowVisible();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const setPayload = useStore.useSetPayload();

  const fetchTrackAndTraceEvents = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const userId = urlParams.get("id") ?? "";
      const response = await getTrackAndTraceEvents(userId);
      setUnionFlowPayload({ userId });
      setUnionFlowPayload({ userId });
      setTrackTraceResponse(response.data);
      setPayload({mailAddress: response.data.email});
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchTrackAndTraceEvents();
  }, []);
  
  return loading ? (
    <LoadingWrapper>
      <Spinner isCentered style={{ position: "relative", right: "auto", top: "auto" }} />
      <p>Vent venligst mens vi finder dine data...</p>
    </LoadingWrapper>
  ) : error ? (
    <LoadingWrapper>
      <ErrorTitle>Der opstod en fejl, prøv venligst senere! </ErrorTitle>
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <Header>
        <HeaderTitle>Hej {response?.name}</HeaderTitle>
        <HeaderSubtitle dangerouslySetInnerHTML={{ __html: response?.welcomeText ?? "" }} />
        {response?.events.map((item) =>
          item.action.name === TrackTracerItemActionsEnum.BuyUnemploymentInsurance ? (
            <HeaderMobilepay>
              <MobilepayImg src="/assets/images/mobilepay.png" alt="MobilePay" />
              <HeaderSubtitleMP>Hvis du endnu ikke har betalt din første regning, kan du gøre det nu via din <b>MobilePay App.</b><br/><br/>
              Dine fremtidige regninger betaler du via <b>Betalingsservice</b> - vi sætter det op for dig.
              </HeaderSubtitleMP> 
            </HeaderMobilepay>
          ) : null
        )}
       
        
      </Header>
      <TraceWrapper>
        {response?.events.map((item, index) => (
          <TrackTraceItem key={index} item={item} />
          ))}
      </TraceWrapper>

      <SimpleModal open={unionFlowVisible}>
        <UnionFlow />
      </SimpleModal>

      <SimpleModal open={salaryInsuranceVisible}>
        <SalaryInsuranceFlow />
      </SimpleModal>
    </Wrapper>
  );
}
