import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 50px;
`;

export const EnrollmentDateWrapper = styled.div`
padding: 16px;
text-align: center;
color: #ffffff;
background: linear-gradient(180deg, #008E9F 36.14%, #006571 100%);
width: 100%;
height:130px;
padding-top:40px;
`;
  
export const EnrollmentDateHeadline = styled.p`
  font-weight: 600;
  font-size: .9em;
  margin-bottom: 0px;
  padding: 10px;
  background-color: var(--color-orange);
  border-radius: 5px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  @media ${devices.tablet} {
    width: 70%;
  }
  @media ${devices.laptop} {
    max-width: 60%;
  }
  @media ${devices.laptopLdesktop} {
    width: 40%;
  }
`;

export const ContentWrapper = styled.div`
  font-size:.8em;
  z-index: 99;
  margin-top: -20px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: rgb(246, 246, 246);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  @media ${devices.tablet} {
    width: 70%;
  }
  @media ${devices.laptop} {
    max-width: 60%;
  }
  @media ${devices.laptopLdesktop} {
    width: 40%;
  }
`;

export const Headline = styled.h1`
  font-weight: 600;
  font-size: var(--font-size-header-medium);
  margin-bottom: 4px;
`;

export const Body = styled.div`
  Padding: 25px 15px 25px 15px;
`;

export const Heighlight = styled.p`
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const ParagraphContainer = styled.p`
  margin-bottom: 24px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  @media ${devices.tablet} {
    width: 70%;
  }
  @media ${devices.laptop} {
    max-width: 60%;
  }
  @media ${devices.laptopLdesktop} {
    width: 50%;
  }
`;

export const Image = styled.img`
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
`;