import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useStore } from "../../../../store/store";
import Button from "../../../../components/Button";
import { Body, ContentWrapper, EnrollmentDateHeadline, EnrollmentDateWrapper, Headline, Heighlight, Image, ImageWrapper, ParagraphContainer, Wrapper } from "./ThankYouStep.style";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";


export default function ThankYouStep() {
  const payload = useStore.usePayload();
  const trackAndTraceUrl = useStore.useTrackAndTraceUrl();
  const WrapperRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    scrollTo({ top: WrapperRef.current.offsetTop - 300, behavior: "smooth" })
  }, [WrapperRef])

  const productsBought = useMemo(() => {
    const productList = [];

    if (payload.wantsUnemploymentBenefits) {
      productList.push("A-kasse");
    }
    if (payload.wantsUnion) {
      productList.push("Fagforening");
    }
    if (payload.wantsFamilyUnion) {
      productList.push("Familiefagforening");
    }
    if (payload.wantsSalaryInsurance) {
      productList.push("Lønsikring");
    }
    if (payload.wantsBasic) {
      productList.push("Ase selvstændig");
    }
    if (payload.wantsHRJura) {
      productList.push("HR Jura");
    }

    return productList;
  }, []);

  const curentDate = useMemo(() => {
    const today = new Date(payload.signupDate);
    const date = today.getDate();
    const month = today.getMonth()+1;
    const year = today.getFullYear();
    
    return date + "/" + month + "/" + year;
  }, [])

  return (
    
    <Wrapper  ref={WrapperRef}>
      <EnrollmentDateWrapper>
          <EnrollmentDateHeadline>Dit medlemsskab er aktivt fra {curentDate}</EnrollmentDateHeadline>
        </EnrollmentDateWrapper>
      <ContentWrapper>
        
        <Body>
          <Headline>Kvittering</Headline>
          <p></p>
          <ParagraphContainer>
            <Heighlight>Velkommen {payload.firstName}!</Heighlight>
            <p>Tak fordi du har valgt os. Vi er glade for at have dig med ombord!</p>
          </ParagraphContainer>
          {payload.wantsUnemploymentBenefits && (
            <ParagraphContainer>
            <Heighlight>For at komme nemt i gang - betal din første regning i dag</Heighlight>
            <p>
              For at komme nemt igang, kan du hurtigt betale din <strong>første regning</strong> med <strong>MobilePay</strong><br/> - bare <strong>swipe</strong> i appen for at aktivere dit medlemskab.
            </p>
          </ParagraphContainer>
          )}
          <ParagraphContainer>
            <p>Dine fremtidige regninger betaler du via Betalingsservice - vi sætter det op for dig.</p>
          </ParagraphContainer>
          {payload.wantsUnemploymentBenefits && (
            <ImageWrapper>
              <Image src="/assets/images/ase-Swipe-i-MP.png" alt="Mobilepay" />
            </ImageWrapper>
          )}
          {(payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student") && (
            <ParagraphContainer>
              <Heighlight>Vil du følge din indmeldelse</Heighlight>
              <p>Du kan holde øje med din indmeldelse og status via linket - det er nemt.</p>
              <Button href={trackAndTraceUrl}>Følg din indmeldelse</Button>
            </ParagraphContainer>
          )}
          {(payload.wantsHRJura || payload.wantsBasic) && (
            <ParagraphContainer>
              <Heighlight>Erhversprodukter</Heighlight>
              <p>Vi ringer dig op inden for to arbejdsdage for at informere mere om produkterne.</p>
            </ParagraphContainer>
          )}
          <p></p>
        </Body>
      </ContentWrapper>
    </Wrapper>
  );
}
