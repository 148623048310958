import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  @media ${devices.tablet} { 
  flex-direction: row;
  gap: 15px;
  }
`;

export const Question = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 0px;
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
`;

export const ColReg = styled.div`
  width: 30%;
  margin-right: 15px;
`;

export const ColAccNumb = styled.div`
  width: 70%;
`;
