import { useEffect, useRef, useState } from "react";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes"
import { useStore } from "../../../../store/store";
import { Container, Dropdown, DropdownItem, Input, InputErrorMessage, Label } from "./AdressInput.style";
import { lookupAddress } from "../../../../utils/api";
import { AddressLookupResponse } from "../../../../types/AddressLookupResponse";
import { useOnClickOutside } from "../../../../hooks/useClickOutside";
import { useKeyPress } from "../../../../hooks/useKeyPress";
import { bool } from "prop-types";

export interface AddressData {
    addressText: string;
    address: string;
    houseNumber: string;
    floor: string;
    door: string;
    city: string;
    zipCode: string;
  }

interface Props {
    id: string, 
    name: string,
    labelText: string,
    placeholder: string,
    autocomplete: string,
    step: AccordionRoutesEnum,
    setSelectedAddress: (address: AddressData) => void;
}

export default function AdressInput({id, name, labelText, placeholder, autocomplete, step, setSelectedAddress} : Props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const accordionRoute = useStore.useAccordionRoute();
    const [validated, setValidated] = useState<boolean|undefined>();
    const [cprIsValid, setCprIsValid] = useState<boolean|undefined>();
    const [loadingAddress, setLoadingAdress] = useState(false);
    const [addresseListResponseDawa, setAddresseListResponseDawa] = useState<AddressLookupResponse[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [inputIsInFocus, setInputIsInFocus] = useState(false);
    const [newSearch, setNewSearch] = useState(false);
    const arrowUpPressed = useKeyPress("ArrowUp");
    const arrowDownPressed = useKeyPress("ArrowDown");
    const enterPressed = useKeyPress("Enter");
    const [selectedIndex, setSelectedIndex] = useState(-1); 
    const payload = useStore.usePayload();

    //Test function, todo delete
    useEffect(() => {
            if(cprIsValid === true && cprIsValid !== undefined) {
                setValidated(true)
            } else if(cprIsValid === false && cprIsValid !== undefined) {
                setValidated(false)
            }
    }, [cprIsValid])

    //Close addresse list dropdown when click outside container
    useOnClickOutside(inputContainerRef, () => {
        setInputIsInFocus(false);
    });

    //Fetch address list from DAWA
    const handleLookupAdress = async (address: string, cursorPosition: string, cursorStartFrom?: string, addressId?: string) => {
        await fetchAdressDawa(address, cursorPosition, cursorStartFrom, addressId);
    };

    const fetchAdressDawa = async (address: string, cursorPosition: string, cursorStartFrom?: string, addressId?: string) => {
        if (!address) return;
        try { 
            setLoadingAdress(true);
            const response = await lookupAddress(address, cursorPosition, cursorStartFrom, addressId);

            setAddresseListResponseDawa(response.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingAdress(false);
        }
    };

    //Resets the address that is selected from the adress list
    const reset = () => {
        setSelectedAddress &&
            setSelectedAddress({
                addressText: "",
                address: "",
                houseNumber: "",
                floor: "",
                door: "",
                city: "",
                zipCode: "",
            })
        setCprIsValid(false);
    }

    //When addressItem in dropdown is clicked on
    const selectAddress = (address: AddressLookupResponse) => {
        //If full address is selected??
        if(address?.type === "adresse") {
            setSelectedAddress &&
            setSelectedAddress({
                addressText: address.tekst,
                address: address.data.vejnavn,
                houseNumber: address.data.husnr,
                floor: address.data.etage ?? "",
                door: address.data.etage ?? "",
                city: address.data.postnrnavn,
                zipCode: address.data.postnr,
            });
            setInputIsInFocus(false);
            setCprIsValid(true);
            //If there is not a full address but id??
        } else if(address?.data.id) {
            handleLookupAdress(address.tekst, address.tekst.length.toString(), undefined, address.data.id);
            //If there is not at full address and no id??
        } else {
            handleLookupAdress(address.tekst, address.tekst.length.toString(), "adgangsadresse", undefined);
        }
        setNewSearch(true);
        setInputValue(address.tekst);
    };


    useEffect(() => {
        if(newSearch && addresseListResponseDawa.length === 1 && addresseListResponseDawa[0].type === "adresse") {
            const addressData = addresseListResponseDawa[0];
            setSelectedAddress &&
                setSelectedAddress({
                    addressText: addressData.tekst,
                    address: addressData.data.vejnavn,
                    houseNumber: addressData.data.husnr,
                    floor: addressData.data.etage ?? "",
                    door: addressData.data.etage ?? "",
                    city: addressData.data.postnrnavn,
                    zipCode: addressData.data.postnr,
                });
            setInputValue(addressData.tekst);
            setNewSearch(false);
            setInputIsInFocus(false);
            setCprIsValid(true);
        }
    }, [addresseListResponseDawa, newSearch])


    //Enable using keyboard to navigate addresse list
    //Navigation up and down list with keyboard
    useEffect(() => {
        if(addresseListResponseDawa) {
            if(arrowDownPressed && selectedIndex + 1 < addresseListResponseDawa.length) {
                setSelectedIndex(selectedIndex + 1);
            }

            if(arrowUpPressed && selectedIndex - 1 >= 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        }
    }, [arrowDownPressed, arrowUpPressed, addresseListResponseDawa]);
    //Choosing item on list with enter 
    useEffect(() => {
        if(enterPressed && addresseListResponseDawa && selectedIndex >= 0) {
            const selectedAddress = addresseListResponseDawa[selectedIndex];
            selectAddress(selectedAddress);
        }
    }, [enterPressed, addresseListResponseDawa])
    

    return(
        <Container ref={inputContainerRef}>
            <Label htmlFor={id}>{labelText}</Label>
            <Input 
                ref={inputRef} 
                id={id}  
                name={name} 
                placeholder={placeholder} 
                autoComplete={autocomplete}
                validated={validated}
                value={inputValue} 
                onChange={(e) => {
                    handleLookupAdress(e.currentTarget.value, e.currentTarget.selectionStart?.toString() ?? "");
                    setInputValue(e.currentTarget.value);
                    reset();
                }}
                onFocus={() => setInputIsInFocus(true)}
                />

            {inputIsInFocus && addresseListResponseDawa.length > 0 && (    
                <Dropdown>
                    <div>
                        {addresseListResponseDawa.map((address, index) =>(
                            <DropdownItem key={index} isFocused={selectedIndex === index} onClick={() => selectAddress(address)}>
                                {address.forslagstekst}
                            </DropdownItem>
                        ))}
                    </div>
                </Dropdown>
            )}

            {(validated !== undefined && accordionRoute === step && !validated) && (
                <InputErrorMessage>Hov, vil du kigge på denne igen?</InputErrorMessage>
            )}
        </Container>
    )
}