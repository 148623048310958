import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Wrapper = styled.div`
  background: linear-gradient(rgb(0, 142, 159) 36.14%, rgb(0, 101, 113) 100%);
  color: #ffffff;
`;

export const Headline = styled.h1`
  font-weight: 600;
  font-size: var(--font-size-header-medium);
  margin-bottom: 24px;
`;

export const Body = styled.div`
  padding: 24px;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;  
  @media ${devices.tablet} {
    width: 80%;
  }
  @media ${devices.laptop} {
    width: 56%;
  }
  @media ${devices.laptopLdesktop} {
    width: 32%; 
  }   
`;

export const ParagraphContainer = styled.div`
  margin-bottom: 24px;
`;

export const Paragraph = styled.p`
  margin-bottom: 4px;
`;

export const SalaryCheckDiv = styled.div`
  margin-top: 32px;
`;

export const Link = styled.a`
  font-weight: 600px; 
  text-decoration: underline;
`;
