import { CampaignImg, CompletedIcon, Content, ContentInner, DivInHeader, Header, HeaderIcon, Navigation, Title, Wrapper } from "./Accordion.style";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import { useStore } from "../../../../store/store";
import Button from "../Button";
import { ButtonBackgroundColorType } from "../Button/Button";

export enum AccordionStateEnum {
  Completed = "Completed",
  Active = "Active",
  Inactive = "Inactive",
}

interface Props {
  title: string;
  step: AccordionRoutesEnum;
  children: React.ReactNode;
  onNext: () => void;
  previousStep?: AccordionRoutesEnum;
  stepComplete: boolean;
  headerImg: string;
}

export default function Accordion({ title, children, step, onNext, previousStep, stepComplete, headerImg }: Props) {
  const expandedHeight = useRef<number>(0);
  const contentInnerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowResize();
  const [height, setHeight] = useState(0);
  const accordionRoute = useStore.useAccordionRoute();
  const accordionRouteCompletedArray = useStore.useAccordionRouteCompletedArray();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const payload = useStore.usePayload();

  const currentState = useMemo(() => {
    if (accordionRoute === step) {
      return AccordionStateEnum.Active;
    } else if (!accordionRouteCompletedArray.includes(step)) {
      return AccordionStateEnum.Inactive;
    } else if (accordionRouteCompletedArray.includes(step)) {
      return AccordionStateEnum.Completed;
    } else {
      return AccordionStateEnum.Inactive;
    }
  }, [accordionRoute, accordionRouteCompletedArray]);

  useEffect(() => {
    if (contentInnerRef.current) {
      expandedHeight.current = contentInnerRef.current?.getBoundingClientRect().height;
    }
  }, [windowSize, payload]);

  useEffect(() => {
    if (currentState === AccordionStateEnum.Active) {
      setHeight(expandedHeight.current);
    } else {
      setHeight(0);
    }
  }, [currentState, expandedHeight, windowSize, payload]);

  const goBack = () => {
    if(previousStep) {
      setAccordionRoute(previousStep, payload);
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollNext = () => {
    onNext()
    scrollTo({ top: scrollRef.current.offsetTop, behavior: "smooth" })
  } 

  return (
    <>
      <Wrapper state={currentState} ref={scrollRef}>
        <Header
          state={currentState}
          onClick={() => {
            if (accordionRouteCompletedArray.includes(step)) {
              setAccordionRoute(step, payload);
              scrollTo({ top: scrollRef.current.offsetTop, behavior: "smooth" })
            }
          }}
        >
          <DivInHeader>
            <HeaderIcon src={headerImg}></HeaderIcon>
            <Title>{title}</Title>
          </DivInHeader>
          {currentState === AccordionStateEnum.Completed && <CompletedIcon />}
        </Header>
        <Content height={height}>
          <ContentInner ref={contentInnerRef}>
            {children}
            {step !== AccordionRoutesEnum.PaymentStep && (
              <Navigation>
                {step != AccordionRoutesEnum.OccupationStep && (
                  <Button bgColor={ButtonBackgroundColorType.Transparent} blockButton={false} onClick={goBack}>
                    <b>Tilbage</b>
                  </Button>
                )}
                <Button bgColor={!stepComplete ? ButtonBackgroundColorType.Grey : ButtonBackgroundColorType.Orange} blockButton={false} onClick={scrollNext} disabled={!stepComplete}>
                  <b>Næste</b>
                </Button>
              </Navigation>
            )}
            {step === AccordionRoutesEnum.PaymentStep && (
              <Navigation>
                <Button bgColor={ButtonBackgroundColorType.Transparent} blockButton={false} onClick={goBack}>
                  <b>Tilbage</b>
                </Button>
                <Button bgColor={!stepComplete ? ButtonBackgroundColorType.Grey : ButtonBackgroundColorType.Orange} blockButton={false} disabled={!stepComplete} onClick={scrollNext}>
                  <b>Meld dig ind</b>
                </Button>
              </Navigation>
            )}
          </ContentInner>
        </Content>
      </Wrapper>
    </>
  );
}
