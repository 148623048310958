import React, { useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "../ProgressBar";
import { useStore } from "../../store/store";
import StepRoutes from "../../constants/Routes";
import { scrollTo } from "../../utils/scrollTo";
import Modal from "../Modal";
import { AseSpinner } from "../../utils/global.styles";
import { BackButton, CampaignImg, Content, Footer, Header, HeaderTitle, Heighlight, Help, HelpButton, HelpLabel, LeftContainer, NextButton, NextLink, OfferBanner, RightContainer, StepTitle } from "./StepContainer.styles";
import data from "../../features/FormEnrollmentNew/Steps/YourConsentStep/text";
import Analytics from "../../utils/analytics";

interface StepProps {
  title?: string;
  subtitle?: string;
  stepTitle?: string;
  progress?: number;
  children: React.ReactNode;
  onNext?: () => void;
  nextDisabled?: boolean;
  hideBackButton?: boolean;
  withSuccessButton?: boolean;
  loading?: boolean;
  buttonText?: string;
  helpText?: string;
  previousDisabled?: boolean;
}

export default function StepContainer({ children, title, subtitle, stepTitle, progress = 0, onNext, nextDisabled = false, previousDisabled = false, hideBackButton = false, loading = false, buttonText, helpText, withSuccessButton }: StepProps) {
  const goBack = useStore.useSetBack();
  const t = useStore.useTranslations();
  const offerEnabled = useStore.useOfferEnabled();
  const payload = useStore.usePayload();
  const testUrl = useStore.useTestUrl();
  const routeStack = useStore.useRouteStack();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [campaignImgUrl, setCampaignImgUrl] = useState("");
  const [occupationUrl, setOccupationUrl] = useState("employee");

//Testing new and old OI
// randomizing which to show after first step in old OI and save in local storage
useMemo(() => { 
  const randomResult = Math.round(Math.random());

  if(!localStorage.getItem("OI") ) {
    if(randomResult === 0) {
      localStorage.setItem("OI", "0")
    }
  
    if(randomResult === 1) {
      localStorage.setItem("OI", "1")
    }
  }
}, []);

 //Sending product parametere to test url
 const preselectProducts = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productcardProducts = urlParams.get("produkt");
    
    //If there are any products set in the url from the productcards
    if(productcardProducts) {
      return productcardProducts  
    } else {
    //If there is no products in the url then check for preslectUnion in translationsfile
    const product = t?.general.preselectUnion

    if (payload.occupation === "Employee" || payload.occupation === "Unemployed") {
        if(product && product === "familyUnion") {
          return "akasse|familiefagforening"
        }
        if(product && product === "union") {
          return "akasse|fagforening"
        }
        return "akasse|fagforening"
    } else if (payload.occupation === "SelfEmployed") {
        return "akasse|hrjura|basis"
    } else if (payload.occupation === "Student") {
      return "akasse|fagforening"
    }

  }
};

//Send choosen occupation as parameter to url
useEffect(() => {
  if(payload.occupation === "Employee") {
    setOccupationUrl("employee")
  }
  if(payload.occupation === "SelfEmployed") {
    setOccupationUrl("selfemployed") 
  }
  if(payload.occupation === "Student") {
    setOccupationUrl("student") 
  }
  if(payload.occupation === "Unemployed") {
    setOccupationUrl("unemployed")
  }
}, [payload.occupation])

//Testing new and old OI end

useEffect(() => {
  setTimeout(() => {
    setIsAnimating(false);
  }, 400);
}, []);

useEffect(() => {
  const form = document.querySelector(`[data-component='form-enrollment']`) as HTMLElement;
  const imageUrl = form?.dataset.imageCampaignUrl;
  setCampaignImgUrl(`${imageUrl}`);
}, [])

/*   const showOfferBanner = useMemo(() => {
    const currentStep = routeStack[routeStack.length - 1];
    if (offerEnabled && currentStep === StepRoutes.OccupationStep && (payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student")) {
      return true;
    } else {
      return false;
    }
  }, [payload, offerEnabled, routeStack]); */

  const showCampaignImg = useMemo(() => {
    const currentStep = routeStack[routeStack.length - 1];
    if(currentStep === StepRoutes.OccupationStep && (payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student")) {
      return true;
    } else {
      return false;
    }
  }, [payload, routeStack]);

  return (
    <>
      <Header>
        {subtitle && <p style={{ margin: 0, fontSize: 16 }}>{subtitle}</p>}
        <HeaderTitle>{title ? title : t?.general.formTitle}</HeaderTitle>
        <ProgressBar progress={progress} />
      </Header>
      {showCampaignImg && campaignImgUrl && 
        <CampaignImg src={campaignImgUrl}/>
      }
      <Content isAnimating={isAnimating}>
        {stepTitle && <StepTitle>{stepTitle}</StepTitle>}
        <div>{children}</div>
      </Content>

      <Footer>
        <LeftContainer>
          <Help isVisible={!!helpText} onClick={() => setShowHelpModal(true)}>
            <HelpLabel>{t?.general.needHelp}</HelpLabel>
            <HelpButton className="button icon open ASEHelpButton">?</HelpButton>
          </Help>
          {!hideBackButton && (
            <BackButton
              className="button secondary icon medium"
              aria-label="Forrige"
              disabled={previousDisabled}
              onClick={() => {
                scrollTo("ASEEnrollmentForm");
                goBack();
              }}
            >
              <svg className="svg-arrow-left">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" href="#svg-arrow-left"></use>
              </svg>
            </BackButton>
          )}
        </LeftContainer>
        <RightContainer>
          {(onNext && routeStack.length === 1 && localStorage.getItem("OI") === "0") && (
            <NextLink 
              href={`${testUrl}?step=2&type=${occupationUrl}&produkt=${preselectProducts()}`}
            >
              {!loading && (buttonText ?? t?.general.next)}
              {loading && <AseSpinner />}
            </NextLink>
          )}
          {(onNext && routeStack.length === 1 && localStorage.getItem("OI") === "1") && (
            <NextButton
              isGreen={!!withSuccessButton}
              className="button"
              tabIndex={1}
              onClick={async () => {
                scrollTo("ASEEnrollmentForm");
                await new Promise((resolve) => setTimeout(resolve, 50));
                await onNext();
              }}
              aria-label="Næste"
              disabled={nextDisabled}
            >
              {!loading && (buttonText ?? t?.general.next)}
              {loading && <AseSpinner />}
            </NextButton>
          )}
          {(onNext && routeStack.length > 1) && (
            <NextButton
              isGreen={!!withSuccessButton}
              className="button"
              tabIndex={1}
              onClick={async () => {
                scrollTo("ASEEnrollmentForm");
                await new Promise((resolve) => setTimeout(resolve, 50));
                await onNext();
              }}
              aria-label="Næste"
              disabled={nextDisabled}
            >
              {!loading && (buttonText ?? t?.general.next)}
              {loading && <AseSpinner />}
            </NextButton>
          )}
        </RightContainer>
      </Footer>
      {helpText && <Modal modalDescriptionHtml={helpText} open={showHelpModal} onClose={() => setShowHelpModal(false)} />}
    </>
  );
}
