import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import ConsentCard from "../../components/ConsentCard";
import { useStore } from "../../../../store/store";
import textContent from "./text";
import { Consent, ConsentContainer, ConsentHeadline, Container} from "./YourConsentStep.style";
import { useMemo, useState } from "react";
import Tooltip from "../../../../components/Tooltip";
import Modal from "../../components/Modal";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import { PaymentFrequencyTypeEnum } from "../../../../types/PaymentFrequencyTypeEnum";
import { fetchSignupDate } from "../../../../utils/api";

enum ConsentType {
  BOTH = "BOTH",
  UNION = "UNION",
  UNEMPLOYMENT_BENEFITS = "UNEMPLOYMENT_BENEFITS",
}

export default function ConsentStep() {
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const setBsPaymentFee = useStore.useSetBsPaymentFee();
  const translations = useStore.useTranslations();
  const productConsentSet = ((payload.wantsFamilyUnion || payload.wantsUnion) && Boolean(payload.consents?.union?.aseCooperation)) || (payload.wantsUnemploymentBenefits && Boolean(payload.consents?.unemploymentBenefits?.aseCooperation));
  const partnerConsentSet = (payload.wantsUnemploymentBenefits && Boolean(payload.consents?.unemploymentBenefits?.member) && Boolean(payload.consents?.unemploymentBenefits?.partner)) || ((payload.wantsFamilyUnion || payload.wantsUnion) && Boolean(payload.consents?.union?.member) && Boolean(payload.consents?.union?.partner));
  const [productConsent, setProductConsent] = useState(productConsentSet);
  const [partnerConsent, setPartnerConsent] = useState(partnerConsentSet);
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const setConsentPayload = () => {
    setPayload({
      consents: {
        union: {
          aseCooperation: (payload.wantsFamilyUnion || payload.wantsUnion) && productConsent,
          member: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
          partner: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
        },
        unemploymentBenefits: {
          aseCooperation: payload.wantsUnemploymentBenefits && productConsent,
          member: payload.wantsUnemploymentBenefits && partnerConsent,
          partner: payload.wantsUnemploymentBenefits && partnerConsent,
        },
      },
    });
  };

  const consentType = useMemo(() => {
    if ((payload.wantsFamilyUnion || payload.wantsUnion) && payload.wantsUnemploymentBenefits) {
      return ConsentType.BOTH;
    } else if (!payload.wantsFamilyUnion && !payload.wantsUnion && payload.wantsUnemploymentBenefits) {
      return ConsentType.UNEMPLOYMENT_BENEFITS;
    } else if ((payload.wantsFamilyUnion || payload.wantsUnion) && !payload.wantsUnemploymentBenefits) {
      return ConsentType.UNION;
    } else {
      return ConsentType.BOTH;
    }
  }, [payload]);

  const text = useMemo(() => {
    switch (consentType) {
      case ConsentType.BOTH:
        return textContent.wantBoth;
      case ConsentType.UNEMPLOYMENT_BENEFITS:
        return textContent.wantUnemploymentBenefits;
      case ConsentType.UNION:
        return textContent.wantUnion;
    }
  }, [consentType]);

  const box2TextComponent = useMemo(() => {
    return (
      <span>
        <b>Jeg accepterer at blive kontaktet om nyheder og medlemstilbud</b> fra {" "}
        <Tooltip isBlockStyle={false} content="<p>Ase består af følgende foreninger:</p><ul><li>Ase a-kasse</li><li>Fagforeningen Ase Lønmodtager</li><li>Ase Selvstændig</li></ul>">
          <span><u>Ase</u></span>
        </Tooltip> {" "}
          og deres samarbejdspartnere. Se listen over Ases samarbejdspartnere {" "}
        <span style={{ cursor: "pointer" }} onClick={() => setShowPartnerList(true)}>
          <u>her</u>
        </span>
        . Det vil altid være{" "}
        <Tooltip isBlockStyle={false} content="<p>Ase består af følgende foreninger:</p><ul><li>Ase a-kasse</li><li>Fagforeningen Ase Lønmodtager</li><li>Ase Selvstændig</li></ul>">
          <span><u>Ase</u></span>
        </Tooltip>
        , der kontakter mig.
      </span>
    );
  }, []);

  const getSignupDate = () => {
    const fetch = async () => {
      try {
        const response = await fetchSignupDate(payload);
        setPayload({ signupDate: response.data.signupdate });
      } catch (err) {
        setShowErrorModal(true);
      }
    };

    console.log(payload)
    if (payload) {
      fetch();
    }
    };

//BsPaymentFee set for next step because of OI splittest, it used to be on the paymentStep
 const bsPaymentFee = () => {
    if (payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly && payload.wantsUnemploymentBenefits) {
      setBsPaymentFee(Number(translations?.paymentStep.bs.quarterlyServiceFee))
    } else if (payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly && payload.wantsUnemploymentBenefits) {
      setBsPaymentFee(Number(translations?.paymentStep.bs.monthlyServiceFee))
    } else {
      setBsPaymentFee(0)
    }
  }
  //BSPaymentFee end

 return (
  <>
   <Accordion 
      title="5. Få mere ud af dit medlemskab"
      step={AccordionRoutesEnum.ConsentStep}
      onNext={() => {
        setConsentPayload()
        
        //Add tracking
        Analytics.getInstance().trackAllConsent("added", payload.consents, TrackingFlowTypeEnum.Vertical);

        setCompletedAccordionRoute(AccordionRoutesEnum.ConsentStep)
        setAccordionRoute(AccordionRoutesEnum.PaymentStep, payload)
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        getSignupDate();
        bsPaymentFee()
      }}
      stepComplete={true}
      previousStep={AccordionRoutesEnum.InformationStep}
      headerImg="/assets/images/order-approve-outline-rounded.svg"
  > 
    <ConsentHeadline>Vælg samtykker</ConsentHeadline>
    <Container>   
      <ConsentCard 
        title="Hurtigere svar og bedre hjælp"
        description={Object.values(text.box1Text).join("")}
        isChecked={productConsent} 
        hasInfoIcon={true}
        modalText={Object.values(text.box1ModalText).join("")}
        modalTextH1="Samarbejde"
        onClick={() => setProductConsent(!productConsent)}
        iconSrc={productConsent ? "/assets/images/cooperation-white.png" : "/assets/images/cooperation-black.png"}
      />
      <ConsentCard 
        title="Stærke personlige tilbud"
        descriptionComponent={box2TextComponent}
        isChecked={partnerConsent} 
        hasInfoIcon={true}
        modalText={Object.values(text.box2ModalText).join("")}
        modalTextH1="Stærke personlige tilbud"
        onClick={() => setPartnerConsent(!partnerConsent)}
        iconSrc={partnerConsent ? "/assets/images/offer-white.png" : "/assets/images/offer-black.png"}
      />
      <ConsentContainer>
        	<Consent>Du kan til enhver tid trække dit samtykke tilbage på MitAse.dk eller ved at kontakte os via e-mail: ase@ase.dk</Consent>
        <Consent>Hos Ase respekterer vi dit privatliv, og beskyttelsen af dine oplysninger er vigtigt for os. <a href="https://www.ase.dk/cookie-privatliv-og-persondata/privatlivsbetingelser" target="_blank"><u>Læs mere her.</u></a></Consent>
      </ConsentContainer>
    </Container>
  </Accordion>
  <Modal modalText={Object.values(textContent.partnerPolicy).join("")} modalTexth1="Ases samarbejdspartnere" modalBlock={false} showModal={showPartnerList} onClose={() => setShowPartnerList(false)} />
  <Modal showModal={showErrorModal} modalText={translations?.summaryStep.errorMessage ?? ""} modalBlock={false} onClose={() => setShowErrorModal(false)}></Modal>
</>
 );
}