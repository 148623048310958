import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Question = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  @media ${devices.tablet} {
    flex-direction: row;
    gap: 15px;
  }
`;
