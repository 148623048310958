import { useEffect, useState } from "react";
import { useStore } from "../../../../store/store";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import { Container, Paragraph } from "./OccupationStep.style";

export default function OccupationStep() {
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const translations = useStore.useTranslations();
  const useResetPayload = useStore.useResetPayload();
  const [source, setSource] = useState<string>();

  //payload is reset when a new occupation is selected and therefore we need to fetch the source again
  useEffect(() => { 
    const form = document.querySelector(`[data-component='form-enrollment']`) as HTMLElement;
    const source = form?.dataset.source ?? "";
    setSource(source);

  }, [source])
  
  //Preselected products if there is preselected union in translations
  const preselectProducts = () => {
  
    const product = translations?.general.preselectUnion

    if (payload.occupation === "Employee") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsSalaryInsurance: false,
          wantsHRJura: false,
          wantsBasic: false,
          wantsUnion: (product && product === "familyUnion") ? false : true,
          wantsFamilyUnion: (product && product === "familyUnion") ? true : false,
        });
    } else if (payload.occupation === "Unemployed") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsSalaryInsurance: false,
          wantsUnion: (product && product === "familyUnion") ? false : true,
          wantsHRJura: false,
          wantsBasic: (product && product === "union") ? true : false,
          wantsFamilyUnion: (product && product === "familyUnion") ? true : false,
        });
    } else if (payload.occupation === "SelfEmployed") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsUnion: false,
          wantsSalaryInsurance: false,
          wantsHRJura: true,
          wantsBasic: true,
          wantsFamilyUnion: false,
        });
    } else if (payload.occupation === "Student") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsUnion: true,
          wantsSalaryInsurance: false,
          wantsHRJura: false,
          wantsBasic: false,
          wantsFamilyUnion: false,
        }); 
    }
  };

 /*  useEffect(() => { 
    console.log(payload)
  },[payload]) */


  return (
    <Accordion
      title="1. Beskæftigelse"
      step={AccordionRoutesEnum.OccupationStep}
      onNext={() => {
        preselectProducts();
        setCompletedAccordionRoute(AccordionRoutesEnum.OccupationStep);
        setAccordionRoute(AccordionRoutesEnum.ProductStep, payload);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      stepComplete={payload.occupation ? true : false}
      headerImg="/assets/images/outline_city.svg"
    >
    
      <Paragraph>{translations?.occupationStep.title}</Paragraph>
      <Container>
        <Button 
          bgColor={ButtonBackgroundColorType.Blue} 
          blockButton={true} 
          isChecked={payload.occupation === "Employee"} 
          onClick={() =>  {
            useResetPayload(); 
            setPayload({ source: source});
            setPayload({ occupation: "Employee" })}
          }
        >
          {translations?.occupationStep.employed}
        </Button>
        <Button 
          bgColor={ButtonBackgroundColorType.Blue} 
          blockButton={true} 
          isChecked={payload.occupation === "Unemployed"} 
          onClick={() => { 
            console.log("Unemployed")
            useResetPayload(); 
            setPayload({ source: source});
            setPayload({ occupation: "Unemployed" })}
          }
        >
          {translations?.occupationStep.unemployed}
        </Button>
      </Container>
      <Container>
        <Button 
          bgColor={ButtonBackgroundColorType.Blue} 
          blockButton={true} 
          isChecked={payload.occupation === "SelfEmployed"} 
          onClick={() => {
            useResetPayload(); 
            setPayload({ source: source});
            setPayload({ occupation: "SelfEmployed" })}
          }
        >
          {translations?.occupationStep.selfEmployed}
        </Button>
        <Button 
          bgColor={ButtonBackgroundColorType.Blue} 
          blockButton={true} 
          isChecked={payload.occupation === "Student"} 
          onClick={() => {
            useResetPayload(); 
            setPayload({ source: source});
            setPayload({ occupation: "Student" })}
          }
        >
          {translations?.occupationStep.student}
        </Button>
      </Container>
    </Accordion>
  );
}
