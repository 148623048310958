import styled from "styled-components";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: grey;
`;

export const Content = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  border: 2px solid red;
`;

export const ContentInner = styled.div``;

export const Title = styled.h2`
  margin: 0;
`;
