import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.laptop} {
    gap: 16px;
    flex-direction: row;
  }
`;

export const Paragraph = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 5px;
`;
