import { useStore } from "../../../../store/store";
import Button from "../../../../components/Button";
import "dayjs/locale/da"
import { Wrapper, ImageWrapper, Image, EnrollmentDateWrapper, EnrollmentDateHeadline, ContentWrapper, Body, Headline, ParagraphContainer, Heighlight } from "./CompletedStep.styles";
import dayjs from "dayjs";
import { useMemo } from "react";

export default function CompletedStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const trackAndTraceUrl = useStore.useTrackAndTraceUrl();

  const getProducts = () => {
    let products = [];

    if (payload.wantsUnemploymentBenefits) {
      products.push(t?.productsStep.unemploymentInsurance.title);
    }

    if (payload.wantsUnion) {
      products.push(t?.productsStep.union.title);
    }

    if (payload.wantsFamilyUnion) {
      products.push(t?.productsStep.familyUnion.title);
    }

    if (payload.wantsSalaryInsurance) {
      products.push("Lønsikring");
    }

    if (payload.wantsBasic) {
      products.push(t?.productsStep.basic.title);
    }

    if (payload.wantsHRJura) {
      products.push(t?.productsStep.hrjura.title);
    }

    return products.reduce((acc, cur, i) => {
      if (i + 1 === products.length && products.length > 1) {
        return acc + " og " + cur;
      } else {
        const seperator = acc ? ", " : "";
        return acc + seperator + cur;
      }
    }, "");
  };

  const curentDate = useMemo(() => {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    return date + "/" + month + "/" + year;
  }, []);

  const showTrackAndTraceButton = useMemo(() => {
    return !!trackAndTraceUrl && (payload.occupation === "Employee" || payload.occupation === "Unemployed");
  }, [payload]);

  return (
    <Wrapper>
      <EnrollmentDateWrapper>
        <EnrollmentDateHeadline>Dit medlemskab er aktivt fra {curentDate}</EnrollmentDateHeadline>
      </EnrollmentDateWrapper>
      <ContentWrapper>
        <Body>
          <Headline>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.94 21.512L9.03 20.307C8.544 20.001 8.302 19.849 8.033 19.84C7.742 19.83 7.495 19.977 6.971 20.307C6.384 20.677 5.217 21.697 4.461 21.211C4 20.913 4 20.158 4 18.646V8C4 5.172 4 3.757 4.827 2.879C5.654 2.001 6.985 2 9.647 2H14.353C17.015 2 18.346 2 19.173 2.879C20 3.757 20 5.172 20 8V18.646C20 20.157 20 20.913 19.538 21.211C18.783 21.697 17.616 20.677 17.029 20.307C16.544 20.001 16.302 19.849 16.032 19.84C15.742 19.83 15.495 19.977 14.971 20.307L13.06 21.512C12.544 21.837 12.287 22 12 22C11.713 22 11.455 21.837 10.94 21.512Z" stroke="#008E9F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <rect x="7" y="6" width="10" height="1.5" rx="0.75" fill="#008E9F" />
              <rect x="7" y="10" width="10" height="1.5" rx="0.75" fill="#008E9F" />
              <rect x="7" y="14" width="10" height="1.5" rx="0.75" fill="#008E9F" />
            </svg>{" "}
            Kvittering
          </Headline>
          <p></p>
          <ParagraphContainer>
            <Heighlight>Velkommen {payload.firstName}!</Heighlight>
            <p>Tak fordi du har valgt os. Vi er glade for at have dig med ombord!</p>
          </ParagraphContainer>
          {payload.wantsUnemploymentBenefits && (
           <ParagraphContainer>
           <Heighlight>For at komme nemt i gang - betal din første regning i dag</Heighlight>
           <p>
             For at komme nemt igang, kan du hurtigt betale din <strong>første regning</strong> med <strong>MobilePay</strong><br/> - bare <strong>swipe</strong> i appen for at aktivere dit medlemskab.
           </p>
         </ParagraphContainer>
          )}
          <ParagraphContainer>
            <p>Dine fremtidige regninger betaler du via Betalingsservice - vi sætter det op for dig.</p>
          </ParagraphContainer>
          {payload.wantsUnemploymentBenefits && (
            <ImageWrapper>
              <Image src="/assets/images/ase-Swipe-i-MP.png" alt="Mobilepay" />
            </ImageWrapper>
          )}
          {(payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student") && (
            <ParagraphContainer>
              <Heighlight>Vil du følge din indmeldelse</Heighlight>
              <p>Du kan holde øje med din indmeldelse og status via linket - det er nemt.</p>
              <Button href={trackAndTraceUrl}>Følg din indmeldelse</Button>
            </ParagraphContainer>
          )}
          {(payload.wantsHRJura || payload.wantsBasic) && (
            <ParagraphContainer>
              <Heighlight>Erhversprodukter</Heighlight>
              <p>Vi ringer dig op inden for to arbejdsdage for at informere mere om produkterne.</p>
            </ParagraphContainer>
          )}
          <p></p>
        </Body>
      </ContentWrapper>
    </Wrapper>
  );
}
