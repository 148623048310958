import styled from "styled-components";

const sizes = {
  mobileL: "500px",
  tablet: "768px",
  laptop: "1024px",
  laptopLdesktop: "1200px",
};

export const devices = {
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopLdesktop: `(min-width: ${sizes.laptopLdesktop})`,
};

export const Background = styled.div`
  width: 100%; 
  background-color: var(--color-blue-50);
`;

export const OIcontainer = styled.div`
  margin-top: -20px;
`;

export const Wrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  width: 95%;

  @media ${devices.mobileL} {
    max-width: 90%;
  }

  @media ${devices.tablet} {
    padding: 20px 0px; 
  }

  @media ${devices.laptop} {
    max-width: 80%;
  }

  @media ${devices.laptopLdesktop} {
    max-width: 48%;
  }
`;

export const AccordionWrapper = styled.div`
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 0px;
`;

export const StepHeader = styled.button`
  background-color: #cecece;
  border: 1px solid #000000;
  border-top-radius: 10px;
  width: 100%;
`;

export const StepContent = styled.div`
  background-color: #ffffff;
  border: 1px solid #000000;
`;

export const CampaignImgBg = styled.div`
  width: 100%;
  height: 115px;
  background-color: var(--color-petrol);
  position: releative;
  display: flex;
  justify-content: center;
  @media ${devices.laptop} {
    height: 245px;
    margin-top: 20px;
  }
`;

export const CampaignImg = styled.img`
  height: 130px;
  width: auto;
  position: absolute;
  margin-top: -15px;
  @media ${devices.laptop} {
    height: 270px;
    margin-top: -25px;
  }
`;

export const GiftcardWrapper = styled.div`
  padding-bottom: 16px;
  background-color: var(--color-blue-50);
`;
