import { Children, ReactNode } from "react";
import { Close, CloseButton, CloseIcon, Container, Content, Headline, ModalBody, ModalContent } from "./Modal.style";

interface Props {
  showModal: boolean;
  modalText?: string;
  modalTexth1?: string;
  modalTextTranslation?: string;
  modalBlock: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function Modal({ showModal = false, modalText, modalTexth1, modalBlock, children, modalTextTranslation, onClose }: Props) {

  return (
    <Container showModal={showModal} modalBlock={modalBlock} onClick={onClose}>
      <ModalContent modalBlock={modalBlock}>
        <Close>
          <CloseButton>
            <CloseIcon onClick={onClose} />
          </CloseButton>
        </Close>
        <ModalBody>
          <Headline>{modalTexth1}</Headline>
          {modalText && (
            <Content dangerouslySetInnerHTML={{ __html: modalText }}></Content>
          )}
          {modalTextTranslation && (
            <Content dangerouslySetInnerHTML={{ __html: modalTextTranslation }}></Content>
          )}
          {children}
        </ModalBody>
      </ModalContent>
    </Container>
  );
}
