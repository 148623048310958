import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Container = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ConsentHeadline = styled.h2`
    font-size: var(--font-size-body);
    margin-bottom: 20px; 
    margin-top: 12px;
    font-weight: 500;
}
`;

export const ConsentContainer = styled.div` 
    margin-top: 18px;
`;

export const Consent = styled.p`
    font-size: var(--font-size-body);
    margin-bottom: 8px;
    margin-top: 8px;
`;

