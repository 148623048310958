import styled from "styled-components"

const getBorderAndBackground = (validated: boolean|undefined) => {
  if(validated !== undefined && !validated) {
  return `
      border: 1px solid var(--color-red);
      background-color: var(--color-red-50);
      outline: 1px solid var(--color-red);
      &:focus-visible {
          outline: 1px solid var(--color-red);
      }
  `;
  } else if(validated !== undefined && validated){
  return `
      border: 1px solid var(--color-green);
      background-color: var(--color-green-50);
      outline: 1px solid var(--color-green);
      &:focus-visible {
          outline: 1px solid var(--color-green);
      }
  `;
  } else {
  return `
      border: 1px solid var(--color-grey-100);
      background-color: #fff;
  `;
  }
};

export const Container = styled.div`
    margin-bottom: 28px;
    position: relative;
    width: 100%;
`;

export const Label = styled.label`
    font-size: var(--font-size-body);
    text-transform: none;                    
`;

export const Input = styled.input<{validated: boolean|undefined}>`
    padding: 11px 16px;
    border-radius: 8px;
    display: block;
    margin-top: 8px;
    width: 100%;
    &:focus-visible {
        outline: 2px solid var(--color-petrol);
    }
    &:hover {
        outline: 2px solid var(--color-petrol);
    }
    ${({ validated }) => getBorderAndBackground(validated)}
`;

export const InputErrorMessage = styled.p`
    font-size: 14px;
    margin-top: 5px;
    padding-left: 2px;
    color: var(--color-red);
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.25);
  z-index: 2000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid var(--color-coal-5);
`;

export const DropdownItem = styled.div<{ isFocused?: boolean}>`
  color: var(--color-coal);
  padding: 10px 24px;
  text-transform: initial;

  &:hover {
    cursor: pointer;
    background-color: var(--color-coal-5);
  }

  ${({ isFocused }) => isFocused && "background-color: var(--color-coal-5);"}
`;