import styled from "styled-components";
import { ButtonBackgroundColorType } from "./Button";

const getHoverBackground = (backgroundType: ButtonBackgroundColorType, isChecked: boolean) => {
  switch (backgroundType) {
    case ButtonBackgroundColorType.Blue:
      if(isChecked) {
        return `
          background-color: var(--color-blue-700);
        `;
      } else {
        return `
          background-color: var(--color-blue-50);
        `;
      }
    case ButtonBackgroundColorType.Grey:
      return `
            Background-color: #ccc; 
            `;
    case ButtonBackgroundColorType.Orange:
      return `
            background-color: var(--color-orange-700);
            `;
    case ButtonBackgroundColorType.Transparent:
      return `
            background-color: var(--color-petrol);
            color: #fff;
            `;
  }
};

export const ButtonWrapper = styled.button<{backgroundType: ButtonBackgroundColorType; blockButton: Boolean; isChecked: boolean; bgColor: string; color: string; borderRadius: string; borderStyle: string; hoverOutline: string;}>`
  width: 100%;
  min-height: 50px;
  border: ${({ borderStyle }) => borderStyle};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  margin: 8px 0px 0px 0px;
  position: relative;
  display: flex;
  &:hover {
    ${({ backgroundType, isChecked}) => getHoverBackground(backgroundType, isChecked)}
    outline: ${({ hoverOutline }) => hoverOutline};
  }

  ${(p) => p.isChecked && `background: var(--color-blue-700);`}
  ${(p) => p.isChecked && `color: #fff;`}
  ${(p) => p.blockButton && `flex: 1`}
`;
