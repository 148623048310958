export enum AccordionRoutesEnum {
  OccupationStep = "OccupationStep",
  ProductStep = "ProductStep",
  CircumstanceStep = "CircumstanceStep",
  InformationStep = "InformationStep",
  ConsentStep =  "ConsentStep",
  PaymentStep = "PaymentStep",
  ContactMeReciept = "ContactMeReciept",
  ContactMeStep = "ContactMeStep",
  ThankYouStep = "ThankYouStep",
}
