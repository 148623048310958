import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  height: 50vh;
`;

export const Header = styled.div`
  width: 100%;
  padding: 36px;
  border-radius: 16px;
  background-color: #f6f1ee;
  color: #4f4a45;
`;

export const HeaderTitle = styled.h2`
  padding-bottom: 1rem;
  font-size: 2rem;
  color: #4f4a45;
  text-transform: initial;
`;

export const HeaderMobilepay = styled.div`
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-bottom: 20px;
  
  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }
`;

export const MobilepayImg = styled.img`
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @media (min-width: 500px) {
    float: left; 
    width: 50px;
    margin-right: 20px;
  }
`;

export const ErrorTitle = styled.h2`
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--color-error);
  text-transform: initial;
  text-align: center;
`;

export const HeaderSubtitle = styled.p``;

export const HeaderSubtitleMP = styled.p`
  margin-bottom: 0px;
`;

export const TraceWrapper = styled.div`
  width: 100%;
  padding: 50px 40px;
`;
