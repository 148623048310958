import { useEffect, useRef, useState } from "react"
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes"
import { Container, Info, Input, InputErrorMessage, Label } from "./CPRinput.style"
import { useStore } from "../../../../store/store"
import { useWindowResize } from "../../../../hooks/useWindowResize"
import Modal from "../../components/Modal";

interface Props {
    id: string, 
    name: string,
    labelText: string,
    placeholder: string,
    autocomplete: string,
    step: AccordionRoutesEnum
    value?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    validated: boolean | undefined,
  }
  
  export default function CPRinput({id, name, labelText, placeholder, autocomplete, value, step, onChange, validated = undefined} : Props) {
    const accordionRoute = useStore.useAccordionRoute();
    const [showInfo, setShowInfo] = useState(false);
    
    return (
      <>
        <Container>
            <Label htmlFor={id}>{labelText}</Label><Info style={{ cursor: "pointer" }} onClick={() => setShowInfo(true)}>i</Info>
            <Input type="text" maxLength={10} id={id} name={name} placeholder={placeholder} autoComplete={autocomplete} validated={validated} value={value} onChange={e => {
                onChange && onChange(e)
            }}/>
            {(validated !== undefined && accordionRoute === step && !validated) && (
                <InputErrorMessage>Hov, vil du kigge på denne igen?</InputErrorMessage>
            )}
        </Container>
        <Modal modalText="Du skal oplyse dit CPR-nummer til Ase, for at du kan få fradrag for dit medlemskab og udbetalt dagpenge, hvis du bliver ledig." modalTexth1="Hvorfor skal du oplyse dit CPR-nummer?" modalBlock={false} showModal={showInfo} onClose={() => setShowInfo(false)} />
      </>
    )
}