import React, { useEffect, useRef, useState } from "react";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { Container, Input, InputErrorMessage, InputErrorSuggestion, Label } from "./InputVF.style";
import { useStore } from "../../../../store/store";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";

export enum InputTypeEnum {
  text = "text",
  tel = "tel",
  email = "email",
}

interface Props {
  id: string;
  name: string;
  type: InputTypeEnum;
  labelText: string;
  placeholder: string;
  autocomplete: string;
  validated: boolean | undefined;
  minlength?: number;
  maxlength?: number;
  step: AccordionRoutesEnum;
  value?: string;
  errorSuggestionText?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default function InputVF({ id, name, type, labelText, placeholder, autocomplete, validated = undefined, minlength, maxlength, step, onChange, value, errorSuggestionText }: Props) {
  const accordionRoute = useStore.useAccordionRoute();

  return (
    <Container>
      <Label htmlFor={id}>{labelText}</Label>
      <Input id={id} type={type} name={name} placeholder={placeholder} autoComplete={autocomplete} minLength={minlength} maxLength={maxlength} validated={validated} onChange={onChange} value={value} />
      {validated !== undefined && accordionRoute === step && !validated && <InputErrorMessage>Hov, vil du kigge på denne igen? {errorSuggestionText && <InputErrorSuggestion>Mente du: {errorSuggestionText}</InputErrorSuggestion>}</InputErrorMessage>}
    </Container>
  );
}
