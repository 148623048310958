import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../../../store/store";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import InputVF from "../../components/InputVF";
import { InputTypeEnum } from "../../components/InputVF/InputVF";
import { Container, Question} from "./InformationStep.style";
import CPRinput from "../../components/CPRinput";
import AdressInput from "../../components/AdressInput";
import ContactMeStep from "../ContactMeStep";
import { AddressData } from "../../components/AdressInput/AdressInput";
import { EmailValidationResponse } from "../../../../types/EmailValidationResponse";
import debounce from "lodash.debounce";
import { postEmailValidation } from "../../../../utils/api";

interface FormData {
  [key: string]: { value: string; error: boolean };
}

export default function InformationStep() {
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const translations = useStore.useTranslations();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const [showContactMe, setShowContactMe] = useState(false);
  const [mobilePhone, setMobilePhone] = useState("");
  const [cpr, setCpr] = useState("");
  const [cprError, setCprError] = useState(Boolean);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailValidationResponse, setEmailValidationResponse] = useState<EmailValidationResponse>({
    email: "",
    score: 0,
    suggestion: "",
    verdict: "",
  });

  const initialFormData = {
    socialSecurityNumber: {
      value: payload.socialSecurityNumber,
      error: !Boolean(payload.socialSecurityNumber),
    },
    firstName: {
      value: payload.firstName,
      error: !Boolean(payload.firstName),
    },
    lastName: {
      value: payload.lastName,
      error: !Boolean(payload.firstName),
    },
    mobilePhone: {
      value: payload.mobilePhone,
      error: !Boolean(payload.mobilePhone),
    },
    email: {
      value: payload.mailAddress,
      error: !Boolean(payload.mailAddress),
    },
    addressText: {
      value: payload.addressText ?? "",
      error: !Boolean(payload.addressText),
    },
    zipCode: {
      value: payload.zipCode ?? "",
      error: !Boolean(payload.zipCode),
    },
    city: {
      value: payload.city ?? "",
      error: !Boolean(payload.city),
    },
    floor: {
      value: payload.floor ?? "",
      error: !Boolean(payload.floor),
    },
    door: {
      value: payload.door ?? "",
      error: !Boolean(payload.door),
    },
    houseNumber: {
      value: payload.houseNumber ?? "",
      error: !Boolean(payload.houseNumber),
    },
  } as FormData;

  const [formData, setFormData] = useState(initialFormData);

  const setValue = (field: string, value: string, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const setSelectedAddress = useCallback(
    (addressData: AddressData) => {
      setFormData({
        ...formData,
        addressText: {
          value: addressData.addressText,
          error: false,
        },
        address: {
          value: addressData.address,
          error: !Boolean(addressData.address),
        },
        city: {
          value: addressData.city,
          error: !Boolean(addressData.city),
        },
        door: {
          value: addressData.door,
          error: false,
        },
        floor: {
          value: addressData.floor,
          error: false,
        },
        houseNumber: {
          value: addressData.houseNumber,
          error: !Boolean(addressData.houseNumber),
        },
        zipCode: {
          value: addressData.zipCode,
          error: !Boolean(addressData.zipCode),
        },
      });
    },
    [formData]
  );

  //Validation
  //Valdition for CPR
  const cprHasError = (cpr: string) => {
    if (!cpr) {
      return true;
    }

    const inputWithRemovedDash = cpr.replace("-", "");
    if (inputWithRemovedDash.length !== 10) {
      return true;
    }

    if (!/^[0-9]+$/.test(inputWithRemovedDash)) {
      return true;
    }

    const day = parseInt(inputWithRemovedDash.substring(0, 2));

    if (day < 0 || day > 31) {
      return true;
    }

    const month = parseInt(inputWithRemovedDash.substring(2, 4));

    if (month < 0 || month > 12) {
      return true;
    }

    let year = parseInt(inputWithRemovedDash.substring(4, 6));

    if (year < 0 || year > 99) {
      return true;
    }

    if(!oldEnough(inputWithRemovedDash)) {
      return true;
    }

    return false;
  };

  const oldEnough = (cpr: any) => {
    if (cpr == undefined) return false;
    cpr = cpr.toString();
    var cutoff = new Date();
    cutoff.setFullYear(
        (cutoff.getFullYear() - 18),
        cutoff.getMonth(),
        cutoff.getDate()
    );
    
    var y = cpr.substr(4,2);
    y = (parseInt(y,10) < 40) ? '20' + y : '19' + y;
    var birthday = new Date(y, (cpr.substr(2,2) - 1), cpr.substr(0,2));
    return (birthday <= cutoff);
  }

  //Validation for firstname and lastname
  const firstnameErrorCheck = (value: string) => {
    const reg = /\d$/;
    if (reg.test(value)) {
      return true
    } else if (!reg.test(value)) {
      return false
    }
  }

  const lastnameErrorCheck = (value: string) => {
    const reg = /\d$/;
    if (reg.test(value)) {
      return true
    } else if (!reg.test(value)) {
      return false
    }
  }


  //Validation for mobilePhone
  const mobilePhoneErrorCheck = (value: string) => {
    const reg = /^\+?[0-9]{3}-?[0-9]{5,12}$/;
    if (!reg.test(value)) {
      return true
    } else {
     return false
    }
  }
//Validation end

//Validation used for styling the input fields
  const cprValidationStyle = useMemo(() => {
    if (cpr) {
      if (cprHasError(cpr) === false && cpr !== undefined) {
        return true;
      } else if (cprHasError(cpr) === true) {
        return false;
      }
    }
  }, [cpr, cprError]);

  const validationFirstname = useMemo(() => {
    if (firstName) {
      if (formData.firstName.error === true) {
        return false;
      } else if (formData.firstName.error === false) {
        return true;
      }
    }
  }, [formData]);

  const validationLastName = useMemo(() => {
    if(lastName) {
      if (formData.lastName.error === true) {
        return false;
      } else if (formData.lastName.error === false) {
        return true; 
      }
    }
  }, [formData]);

  const validationmobilePhone = useMemo(() => {
    if (mobilePhone) {
      if(formData.mobilePhone.error === true) {
        return false;
      } else if (formData.mobilePhone.error === false) {
        return true;
      }
    }
  }, [formData]);
  //Validation used for styling the input fields end

  //Email validation
  const emailErrorCheck = () => {
    if (emailValidationResponse.verdict === "Invalid") {
      return false;
    } else if (formData.email.error === false) {
      return true;
    }
  };

  useEffect(() => {
    setValue("email", emailValidationResponse.email, !emailValidationResponse.verdict || emailValidationResponse.verdict === "Invalid" ? true : false);
  }, [emailValidationResponse]);

  const validatingEmailResponse = async (email: string) => {
    if (!email) return;
    try {
      const response = await postEmailValidation(email);
      setEmailValidationResponse(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedEmailValidation = useRef(
    debounce(async (email: string) => {
      await validatingEmailResponse(email);
    }, 300)
  ).current;

  const emailValidation = async (email: string) => {
    debouncedEmailValidation(email);
  };
  //Email validation end

  const nextStepAllowed = useMemo(() => {
    const formHasErrors = Object.values(formData)
      .map((v: any) => v.error)
      .includes(true);

    if (formHasErrors) {
      return false;
    } else if (!formHasErrors) {
      return true;
    } else {
      return false;
    }
  }, [formData, emailValidationResponse]);

  const next = () => {
    if (payload.isDanishResident) {
      setAccordionRoute(AccordionRoutesEnum.ConsentStep, payload);
    } else if (!payload.isDanishResident) {
      setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
      setShowContactMe(true);
    }
  };


  return (
    <>
      <Accordion
        title="4. Dine oplysninger"
        step={AccordionRoutesEnum.InformationStep}
        onNext={() => {
          const informationData = {
            firstName: formData.firstName.value,
            lastName: formData.lastName.value,
            socialSecurityNumber: formData.socialSecurityNumber.value,
            address: formData.address.value,
            addressText: formData.addressText.value,
            city: formData.city.value,
            zipCode: formData.zipCode.value,
            houseNumber: formData.houseNumber.value ?? "",
            floor: formData.floor.value ?? "",
            door: formData.door.value ?? "",
            mobilePhone: formData.mobilePhone.value,
            mailAddress: formData.email.value,
          };
          setCompletedAccordionRoute(AccordionRoutesEnum.InformationStep);
          setPayload(informationData);
          next();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        stepComplete={nextStepAllowed}
        previousStep={AccordionRoutesEnum.CircumstanceStep}
        headerImg="/assets/images/id-card-h.svg"
      >
        <Question>Bor du i Danmark?</Question>
        <Container>
          <Button onClick={() => setPayload({ isDanishResident: true })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.isDanishResident === true}>
            {translations?.general.answerYes}
          </Button>
          <Button onClick={() => setPayload({ isDanishResident: false })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.isDanishResident === false}>
          {translations?.general.answerNo}
          </Button>
        </Container>
        <CPRinput 
          id="informationStep_CPRinput" 
          name="infoStep_CPR" 
          labelText="CPR" 
          placeholder="Angiv CPR-nummer"
          autocomplete="on"
          value={cpr} 
          step={AccordionRoutesEnum.InformationStep} 
          onChange={(e) => {
            setCpr(e.currentTarget.value);
            setValue("socialSecurityNumber", e.currentTarget.value, cprHasError(e.currentTarget.value) || !e.currentTarget.value);
          }} 
          validated={cprValidationStyle} />
        <InputVF
          id="informationStep_firstNames"
          type={InputTypeEnum.text}
          name="infoStep_firstname"
          labelText="Fornavne"
          placeholder="Angiv fornavne"
          autocomplete="given-name"
          value={firstName}
          validated={validationFirstname}
          step={AccordionRoutesEnum.InformationStep}
          onChange={(e) => {
            setFirstname(e.currentTarget.value);
            setValue("firstName", e.currentTarget.value, firstnameErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
          }}
        />
        <InputVF
          id="informationStep_lastName"
          type={InputTypeEnum.text}
          name="infoStep_lastname"
          labelText="Efternavn"
          placeholder="Angiv efternavn"
          autocomplete="family-name"
          value={lastName}
          validated={validationLastName}
          step={AccordionRoutesEnum.InformationStep}
          onChange={(e) => {
            setLastName(e.currentTarget.value);
            setValue("lastName", e.currentTarget.value, lastnameErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
          }}
        />
        <AdressInput 
          id="informationStep_adresse" 
          name="infoStep_adresse" 
          labelText="Adresse" 
          placeholder="Angiv adresse" 
          autocomplete="address-line1"
          step={AccordionRoutesEnum.InformationStep} 
          setSelectedAddress={setSelectedAddress} 
        />
        <InputVF
          id="informationStep_mobilePhone"
          type={InputTypeEnum.tel}
          name="infoStep_mobilePhone"
          labelText="Telefon"
          placeholder="Angiv telefonnummer"
          autocomplete="tel"
          validated={validationmobilePhone}
          step={AccordionRoutesEnum.InformationStep}
          value={mobilePhone}
          onChange={(e) => {
            setMobilePhone(e.currentTarget.value);
            setValue("mobilePhone", e.currentTarget.value, mobilePhoneErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
          }}
        />
        <InputVF
          id="informationStep_email"
          type={InputTypeEnum.email}
          name="infoStep_email"
          labelText="E-mail"
          placeholder="Angiv e-mail"
          autocomplete="email"
          step={AccordionRoutesEnum.InformationStep}
          onChange={(e) => {
            setValue("email", e.currentTarget.value, true);
            emailValidation(e.currentTarget.value);
          }}
          validated={emailErrorCheck()}
          errorSuggestionText={emailValidationResponse.suggestion}
        />
      </Accordion>

      <ContactMeStep showContactMe={showContactMe} onClose={() => setShowContactMe(false)} />
    </>
  );
}
