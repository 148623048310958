import styled, { css } from "styled-components";

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 25px;
  color: white;
  font-family: "ITC Avant Garde Gothic W01", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  padding: 12px 36px;
  position: relative;
  text-decoration: none;
  transform: perspective(1px) translateZ(0);
  white-space: nowrap;
  transition: background 0.3s ease-in-out;
  position: relative;
  border: none;
  cursor: pointer;
  width: 100%;
`;

export const ButtonWrapper = styled.button<{
  apperance: "default" | "complete";
}>`
  ${buttonStyles}
  background: ${(p) => background[p.apperance]};

  &:hover {
    background: ${(p) => backgroundHover[p.apperance]};
  }

  &:disabled {
    opacity: 0.25;
    &:hover {
      background: ${(p) => background[p.apperance]};
    }
  }
`;

export const LinkWrapper = styled.a<{
  apperance: "default" | "complete";
}>`
  ${buttonStyles}
  background: ${(p) => background[p.apperance]};

  &:hover {
    background: ${(p) => backgroundHover[p.apperance]};
  }

  &:disabled {
    opacity: 0.25;
    &:hover {
      background: ${(p) => background[p.apperance]};
    }
  }
`;

const background = {
  default: "linear-gradient(312deg, rgba(215, 140, 44, 1) 24%, rgba(215, 111, 44, 1) 100%)",
  complete: "linear-gradient(306deg, rgba(81,173,140,1) 24%, rgba(87,188,152,1) 100%)",
};

const backgroundHover = {
  default: "linear-gradient(312deg, rgba(215, 140, 44, 1) 24%, rgba(203, 104, 41, 1) 100%)",
  complete: "linear-gradient(306deg, rgba(78,166,134,1) 24%, rgba(83,179,144,1) 100%)",
};
