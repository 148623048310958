import styled from "styled-components"

const getBorderAndBackground = (validated: boolean|undefined) => {
    if(validated !== undefined && !validated) {
    return `
        border: 1px solid var(--color-red);
        background-color: var(--color-red-50);
        outline: 1px solid var(--color-red);
        &:focus-visible {
            outline: 1px solid var(--color-red);
        }
    `;
    } else if(validated !== undefined && validated){
    return `
        border: 1px solid var(--color-green);
        background-color: var(--color-green-50);
        outline: 1px solid var(--color-green);
        &:focus-visible {
            outline: 1px solid var(--color-green);
        }
    `;
    } else {
    return `
        border: 1px solid var(--color-grey-100);
        background-color: #fff;
    `;
    }
};

export const Container = styled.div`
    margin-bottom: 28px;
    position: relative;
`;

export const Label = styled.label`
    font-size: var(--font-size-body);
    text-transform: none;                    
`;

export const Info = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    width: 20px;
    height: 20px;
    border-radius: 11px;
    background-color: var(--color-orange);
    margin-right: 5px;
    font-size: var(--font-size-body);
    margin-left: 8px;
`;

export const Input = styled.input<{validated: boolean|undefined}>`
    padding: 11px 16px;
    border-radius: 8px;
    display: block;
    margin-top: 8px;
    width: 100%;
    &:focus-visible {
        outline: 2px solid var(--color-petrol);
    }
    &:hover {
        outline: 2px solid var(--color-petrol);
    }  
    ${({ validated }) => getBorderAndBackground(validated)}
`;

export const InputErrorMessage = styled.p`
    font-size: 14px;
    margin-top: 5px;
    padding-left: 2px;
    color: var(--color-red);
`;