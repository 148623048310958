import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Background, CampaignImg, CampaignImgBg, GiftcardWrapper, OIcontainer, Wrapper } from "./FormEnrollmentNew.styles";
import OccupationStep from "./Steps/OccupationStep";
import ProductStep from "./Steps/ProductStep";
import CircumstanceStep from "./Steps/CircumstanceStep";
import InformationStep from "./Steps/InformationStep";
import YourConsentStep from "./Steps/YourConsentStep";
import PaymentStep from "./Steps/PaymentStep";
import ThankYouStep from "./Steps/ThankYouStep";
import { useStore } from "../../store/store";
import ContactMeReciept from "./Steps/ContactMeReciept";
import { fetchTranslations, fetchUnemploymentInsuranceOptions, fetchUnionOptions } from "../../utils/api";
import { useWindowResize } from "../../hooks/useWindowResize";
import axios from "axios";
import { AccordionRoutesEnum } from "../../types/AccordionRoutes";
import GiftCardPopup from "../../components/GiftCardPopup";
import Analytics from "../../utils/analytics";
import { PayloadModel } from "../../types/PayloadModel";
import { TrackingFlowTypeEnum } from "../../types/TrackingFlowTypeEnum";


export enum VisibleLayoutEnum {
  Accordion = "Accordion",
  ThankYou = "ThankYou",
  CallMeReciept = "CallMeReciept"
}

export default function FormEnrollmentNew() {
  const payload = useStore.usePayload();
  const translations = useStore.useTranslations();
  const switchLayout = useStore.useSwitchLayout();
  const accordionRoute = useStore.useAccordionRoute();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const setTranslations = useStore.useSetTranslation();
  const setOfferEnabled = useStore.useSetOfferEnabled();
  const setPayload = useStore.useSetPayload();
  const setTestUrl = useStore.useSetTestUrl(); 
  const setUnemploymentInsuranceOptions = useStore.useSetUnimploymentInsuranceOptions();
  const setUnionOptions = useStore.useSetUnionOptions();
  const [campaignImgUrl, setCampaignImgUrl] = useState("");
  const [campaignMobileImgUrl, setCampaignMobileImgUrl] = useState("");
  const [source, setSource] = useState<string>();
  const windowSize = useWindowResize();
  const elementRef = useRef() as MutableRefObject<HTMLDivElement>;
  const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setPayload({ ipAddress: res.data.ip });
  };

  useEffect(() => {
    const fetchData = async () => {
      try { 
        let translations: any;
        const settingsUrl = form?.dataset.jsonSettingsUrl;
        
        translations = await fetchTranslations(settingsUrl);

        setTranslations(translations.data)
        
        const unemploymentInsuranceOptions = await fetchUnemploymentInsuranceOptions();
        const unionOptions = await fetchUnionOptions();
        
        setUnemploymentInsuranceOptions(unemploymentInsuranceOptions.data);
        setUnionOptions(unionOptions.data);

         // If an occupation is passed in the query string, set it as the default value
         const queryString = window.location.search;
         const urlParams = new URLSearchParams(queryString);
         const occupation = urlParams.get("type");
 
         if (occupation === "employee") {
           setPayload({ occupation: "Employee" });
         }
         if (occupation === "selfemployed") {
           setPayload({ occupation: "SelfEmployed" });
         }
         if (occupation === "unemployed") {
           setPayload({ occupation: "Unemployed" });
         }
         if (occupation === "student") {
           setPayload({ occupation: "Student" });
         }
 
         getIpAddress();

 
      } catch (err) {}
    };

    //const initialized = useRef(false)


    //Preselected occupation via URL
/*     useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const occupationFromUrl = urlParams.get("occupation")
  
      if (!initialized.current) {
        
        if(occupationFromUrl) {
          initialized.current = true
          
          console.log("occupationUrl")
          setPayload({
            occupation: occupationFromUrl
          })
    
        }  
      } 
    }, [payload.occupation])
 */
    
    const form = document.querySelector(`[data-component='form-enrollment-new']`) as HTMLElement;
    
    fetchData();

    const source = form?.dataset.source ?? "";
    const affiliate = form?.dataset.affiliate;
    const enableDiscounts = form?.dataset.enableDiscounts == "True" ? true : false;
    const nodeKey = form?.dataset.settingsNodeKey;

    setOfferEnabled(enableDiscounts);
    setPayload({ settingsNodeKey: nodeKey, source: source, affiliate: affiliate });
    setSource(source);

  }, []);

  //Getting img from Umbraco backend
  useEffect(() => {
    const form = document.querySelector(`[data-component='form-enrollment-new']`) as HTMLElement;
    const imageUrl = form?.dataset.imageCampaignUrl;
    const mobileImageUrl = form?.dataset.mobileImageCampaignUrl;
    setCampaignImgUrl(`${imageUrl}`);
    setCampaignMobileImgUrl(`${mobileImageUrl}`)
  }, [])

  const currentWidth = useMemo(() => {
    return elementRef.current?.getBoundingClientRect().width;
  }, [windowSize])

  const showGiftCardPopup = useMemo(() => {
    return switchLayout !== VisibleLayoutEnum.ThankYou && switchLayout !== VisibleLayoutEnum.CallMeReciept && accordionRoute !== AccordionRoutesEnum.ContactMeStep;
  }, [accordionRoute, VisibleLayoutEnum]);

  
  //Tracking for AB splittest 
 /*  const setTracking = (route: string, flowType: TrackingFlowTypeEnum, payload: PayloadModel) => {
    Analytics.getInstance().trackStep(route.toString(), flowType, payload);
  }
 */
  //Test of new OI
  //Check if occupation needs to be skipped
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const step = urlParams.get("step");

    if(step === "2") {
      setCompletedAccordionRoute(AccordionRoutesEnum.OccupationStep);
      Analytics.getInstance().trackStep(AccordionRoutesEnum.OccupationStep.toString(), TrackingFlowTypeEnum.STANDRARD, payload);
      setAccordionRoute(AccordionRoutesEnum.ProductStep, payload);
    }
    
  }, [])

  return (

    <>
    {showGiftCardPopup === true && (
      <GiftcardWrapper>
        <GiftCardPopup isFront={true} source={source!} />
      </GiftcardWrapper>
    )}
    {(switchLayout === VisibleLayoutEnum.Accordion && campaignImgUrl) && (
    <CampaignImgBg>
      {(currentWidth < 768 && campaignMobileImgUrl) && (
        <CampaignImg src={campaignMobileImgUrl}/>
      )}
      {(currentWidth >= 768 || (currentWidth < 768 && !campaignMobileImgUrl)) && (
        <CampaignImg src={campaignImgUrl}/>
      )}
    </CampaignImgBg>  
    )}
    {switchLayout === VisibleLayoutEnum.Accordion &&
    <Background ref={elementRef}>   
      <Wrapper>
        <OccupationStep />
        <ProductStep />
        <CircumstanceStep />
        <InformationStep />
        <YourConsentStep />
        <PaymentStep />
      </Wrapper>
    </Background> 
    }
    {switchLayout === VisibleLayoutEnum.ThankYou &&
      <ThankYouStep />
    }
    {switchLayout === VisibleLayoutEnum.CallMeReciept &&
      <ContactMeReciept />
    }
    </>
  );
}
