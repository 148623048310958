import { SalaryInsuranceItemModel } from "../../types/SalaryInsuranceItemModel";
import { SalaryInsuranceRequestModel } from "../../types/SalaryInsuranceRequestModel";
import { SalaryInsuranceStateEnum } from "../../types/SalaryInsuranceStateEnum";
import { Store } from "../store";
import { StateCreator } from "zustand";

export type SalaryInsuranceSlice = {
  salaryInsuranceState: SalaryInsuranceStateEnum;
  salaryInsuranceData: SalaryInsuranceRequestModel;
  minGrossPay: number;
  maxGrossPay: number;
  thresholdGrossPay: number;
  unemploymentInsuranceAmount: number;
  basicOptions: SalaryInsuranceItemModel[];
  extendedOptions: SalaryInsuranceItemModel[];
  setSalaryInsuranceData: (data: { [key: string]: string | number }) => void;
  setSalaryInsuranceState: (state: SalaryInsuranceStateEnum) => void;
  setSalaryInsuranceConfig: (minGrossPay: number, maxGrossPay: number, thresholdGrossPay: number, unemploymentInsuranceAmount: number, contentId: string) => void;
  setBasicOptions: (options: SalaryInsuranceItemModel[]) => void;
  setExtendedOptions: (options: SalaryInsuranceItemModel[]) => void;
};

export const salaryInsuranceInitialState = {
  salaryInsuranceState: SalaryInsuranceStateEnum.START_SCREEN,
  salaryInsuranceData: {
    name: "",
    phone: "",
    contentid: "",
    grosspay: "",
    email: "",
    dinpris: "",
    payout: "",
    payoutduration: 3,
    product: "Basis",
    cookie: "",
  },
  basicOptions: [] as SalaryInsuranceItemModel[],
  extendedOptions: [] as SalaryInsuranceItemModel[],
  minGrossPay: 0,
  maxGrossPay: 0,
  thresholdGrossPay: 0,
  unemploymentInsuranceAmount: 0,
} as SalaryInsuranceSlice;

export const createSalaryInsuranceSlice: StateCreator<SalaryInsuranceSlice, [], [], SalaryInsuranceSlice> = (set) =>
  ({
    ...salaryInsuranceInitialState,
    setSalaryInsuranceData: (data: { [key: string]: string | number }) => {
      set((state: Store) => {
        return {
          ...state,
          salaryInsuranceData: {
            ...state.salaryInsuranceData,
            ...data,
          },
        };
      });
    },
    setSalaryInsuranceState: (salaryInsuranceState: SalaryInsuranceStateEnum) => {
      set((state) => ({
        ...state,
        salaryInsuranceState,
      }));
    },
    setSalaryInsuranceConfig: (minGrossPay: number, maxGrossPay: number, thresholdGrossPay: number, unemploymentInsuranceAmount: number, contentid: string) => {
      set((state) => ({
        ...state,
        minGrossPay,
        maxGrossPay,
        thresholdGrossPay,
        unemploymentInsuranceAmount,
        salaryInsuranceData: {
          ...state.salaryInsuranceData,
          contentid,
        },
      }));
    },
    setBasicOptions: (options: SalaryInsuranceItemModel[]) => {
      set((state) => ({
        ...state,
        basicOptions: options,
      }));
    },
    setExtendedOptions: (options: SalaryInsuranceItemModel[]) => {
      set((state) => ({
        ...state,
        extendedOptions: options,
      }));
    },
  } as SalaryInsuranceSlice);
