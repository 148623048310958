import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import { Close, CloseButton, CloseIcon, Consent, ConsentLink, Container, Description, Headline, ModalBody, ModalContent, Navigation } from "./ContactMeStep.style";
import InputVF from "../../components/InputVF";
import { InputTypeEnum } from "../../components/InputVF/InputVF";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../../../store/store";
import { EmailValidationResponse } from "../../../../types/EmailValidationResponse";
import { postEmailValidation } from "../../../../utils/api";
import debounce from "lodash.debounce";
import { VisibleLayoutEnum } from "../../FormEnrollmentNew";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

interface Props {
  showContactMe: boolean;
  onClose: () => void;
}

interface FormData {
  [key: string]: { value: string; error: boolean };
}

export default function ContactMeStep({ showContactMe = false, onClose }: Props) {
  const payload = useStore.usePayload();
  const translations = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setSwitchLayout = useStore.useSetSwitchLayout();
  const [nextAllowed, setNextAllowed] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [emailValidationResponse, setEmailValidationResponse] = useState<EmailValidationResponse>({
    email: "",
    score: 0,
    suggestion: "",
    verdict: "",
  });

  interface FormData {
    [key: string]: { value: string | boolean; error: boolean };
  }  

  const initialFormData = {
    firstName: {
      value: payload.firstName,
      error: !Boolean(payload.firstName),
    },
    lastName: {
      value: payload.lastName,
      error: !Boolean(payload.firstName),
    },
    mobilePhone: {
      value: payload.mobilePhone,
      error: !Boolean(payload.mobilePhone),
    },
    email: {
      value: payload.mailAddress,
      error: !Boolean(payload.mailAddress),
    }
  } as FormData;

  const [formData, setFormData] = useState(initialFormData);

  const setValue = (field: string, value: string | boolean, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const consentText = translations?.needToContactYouStep.consent

  //Validation for firstname
  const firstnameErrorCheck = (value: string) => {
    const reg = /\d$/;
    if (reg.test(value)) {
      return true
    } else if (!reg.test(value)) {
      return false
    }
  }

  //Validation for firstname and lastname
  const lastnameErrorCheck = (value: string) => {
    const reg = /\d$/;
    if (reg.test(value)) {
      return true
    } else if (!reg.test(value)) {
      return false
    }
  }

  //Validation for mobilePhone
  const mobilePhoneErrorCheck = (value: string) => {
    const reg = /^\+?[0-9]{3}-?[0-9]{5,12}$/;
    if (!reg.test(value)) {
      return true
    } else {
     return false
    }
  }

  //Validation used for styling the input fields  
  const validationFirstname = useMemo(() => {
    if (firstName) {
      if (formData.firstName.error === true) {
        return false;
      } else if (formData.firstName.error === false) {
        return true;
      }
    }
  }, [formData]);

  const validationLastName = useMemo(() => {
    if(lastName) {
      if (formData.lastName.error === true) {
        return false;
      } else if (formData.lastName.error === false) {
        return true; 
      }
    }
  }, [formData]);

  const validationMobilePhone = useMemo(() => {
    if (mobilePhone) {
      if(formData.mobilePhone.error === true) {
        return false;
      } else if (formData.mobilePhone.error === false) {
        return true;
      }
    }
  }, [formData]);
  //Validation used for styling the input fields end


//Email validation
  const emailErrorCheck = () => {
    if (emailValidationResponse.verdict === "Invalid") {
      return false;
    } else if (formData.email.error === false) {
      return true;
    }
  };

  useEffect(() => {
    setValue("email", emailValidationResponse.email, !emailValidationResponse.verdict || emailValidationResponse.verdict === "Invalid" ? true : false);
  }, [emailValidationResponse]);

  const validatingEmailResponse = async (email: string) => {
    if (!email) return;
    try {
      const response = await postEmailValidation(email);
      setEmailValidationResponse(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedEmailValidation = useRef(
    debounce(async (email: string) => {
      await validatingEmailResponse(email);
    }, 300)
  ).current;

  const emailValidation = async (email: string) => {
    debouncedEmailValidation(email);
  };
  //Email validation end


  useEffect(() => {
    const formHasErrors = Object.values(formData)
    .map((v: any) => v.error)
    .includes(true);

    if(!formHasErrors) {
      setNextAllowed(true);
    }
  }, [formData])

  const onSend = () => {
    //Add tracking
    Analytics.getInstance().trackNeedToContact(payload, TrackingFlowTypeEnum.Vertical, formData.email.value);
    
    const informationData = {
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      mobilePhone: formData.mobilePhone.value,
      mailAddress: formData.email.value,
      contactMe: true
    };
    setCompletedAccordionRoute(AccordionRoutesEnum.ContactMeStep);
    setPayload(informationData);
    setSwitchLayout(VisibleLayoutEnum.CallMeReciept)
    onClose
  }  

  return (
    <Container showContactMe={showContactMe}>
      <ModalContent>
        <Close>
          <CloseButton>
            <CloseIcon onClick={onClose} />
          </CloseButton>
        </Close>
        <ModalBody>
          <Headline>Brug for at tale med dig</Headline>
          <Description>For at kunne give dig den bedste service, har vi brug for at snakke med dig om din situation.<br/> Udfyld venligst dine oplysninger, så ringer vi dig op hurtigst muligt.</Description>
          <InputVF 
            type={InputTypeEnum.text} 
            id="contactMe-firstname" 
            name="contact-firstname" 
            labelText="Fornavn" 
            placeholder="Indtast fornavn her"
            autocomplete="given-name" 
            validated={validationFirstname} 
            step={AccordionRoutesEnum.ContactMeStep}
            onChange={(e) => {
              setFirstname(e.currentTarget.value);
              setValue("firstName", e.currentTarget.value, firstnameErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
            }}
          />
          <InputVF 
            type={InputTypeEnum.text} 
            id="contactMe-lastname" 
            name="contact-lastname" 
            labelText="Efternavn" 
            placeholder="Indtast efternavn her" 
            autocomplete="family-name"
            validated={validationLastName} 
            step={AccordionRoutesEnum.ContactMeStep} 
            onChange={(e) => {
              setLastName(e.currentTarget.value);
              setValue("lastName", e.currentTarget.value, lastnameErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
            }}
          />
          <InputVF 
            type={InputTypeEnum.tel} 
            id="contactMe-telephone" 
            name="contact-tel" 
            labelText="Telefon" 
            placeholder="indtast telefonnummer her" 
            autocomplete="tel"
            validated={validationMobilePhone} 
            step={AccordionRoutesEnum.ContactMeStep} 
            onChange={(e) => {
              setMobilePhone(e.currentTarget.value);
              setValue("mobilePhone", e.currentTarget.value, mobilePhoneErrorCheck(e.currentTarget.value) || !e.currentTarget.value);
            }}
          />
          <InputVF 
            type={InputTypeEnum.email} 
            id="contactMe-email" 
            name="contact-email" 
            labelText="Email" 
            placeholder="Indtast email her"
            autocomplete="email"
            validated={emailErrorCheck()} 
            step={AccordionRoutesEnum.ContactMeStep}
            onChange={(e) => {
              setValue("email", e.currentTarget.value, true);
              emailValidation(e.currentTarget.value);
            }}
            errorSuggestionText={emailValidationResponse.suggestion} 
            />
          <Consent>Ase må gerne gemme mine oplysninger i 14 dage, for at kunne færdiggøre min indmeldelse. <ConsentLink href='https://www.ase.dk/cookie-privatliv-og-persondata' target='_blank'>Læs mere her.</ConsentLink></Consent>
          <Navigation>
            <Button bgColor={ButtonBackgroundColorType.Transparent} blockButton={false} onClick={onClose}>Tilbage</Button>
            <Button bgColor={nextAllowed ? ButtonBackgroundColorType.Orange : ButtonBackgroundColorType.Grey} disabled={nextAllowed ? false : true} blockButton={false} onClick={onSend}>
              Send
            </Button>
          </Navigation>
        </ModalBody>
      </ModalContent>
    </Container>
  );
}
