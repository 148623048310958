import { BiInfoCircle } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  flex: 0 1 100%;
`;

export const ConsentCardWrapper = styled.div<{ isChecked: boolean }>`
  padding: 5px;
  background-color: ${({ isChecked }) => (isChecked ? "var(--color-blue-700)" : "var(--color-blue-50)")};
  border: ${({ isChecked }) => (isChecked ? "1px solid var(--color-blue-700)" : "1px solid #C6E7E9")};
  border-radius: 8px;
  color: ${({ isChecked }) => (isChecked ? "#ffffff" : "var(--color-coal)")};
  &:hover {
    outline: 2px solid var(--color-petrol);
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  @media ${devices.tablet} {
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
padding: 10px 0px;
@media ${devices.tablet} {
  padding: 10px 15px 10px 0px;
  }
`;

export const Icon = styled.img`
  width: auto;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
`;

export const Title = styled.p<{ isChecked: boolean }>`
  color: ${({ isChecked }) => (isChecked ? "#ffffff" : "var(--color-orange)")};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0px;
`;

export const Description = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 0px;
  font-size: 14px;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const InfoIcon = styled(BiInfoCircle)`
  font-size: 25px;
  cursor: pointer;
`;

export const CheckContainer = styled.div`
`;

export const Unchecked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--color-blue-700);
`;

export const Checked = styled(FaCheck)`
  color: var(--color-orange);
  font-size: 0.6em;
`;
