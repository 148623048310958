import { useEffect, useMemo, useState } from "react";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import { ButtonContainer, ColAccNumb, ColReg, Container, InputRow, Question } from "./PaymentStep.style";
import { useStore } from "../../../../store/store";
import { PaymentFrequencyTypeEnum } from "../../../../types/PaymentFrequencyTypeEnum";
import InputVF from "../../components/InputVF";
import { InputTypeEnum } from "../../components/InputVF/InputVF";
import Reciept from "../../components/Reciept";
import { VisibleLayoutEnum } from "../../FormEnrollmentNew";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import { fetchSignupDate, postEnrollment } from "../../../../utils/api";
import Modal from "../../components/Modal";

export default function PaymentStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const [regNumber, setRegNumber] = useState("");
  const [regNumberSucces, setRegNumberSucces] = useState (false);
  const [regNumberHasError, setRegNumberHasError] = useState(Boolean);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberSucces, setAccountNumberSucces] = useState(false);
  const [accountNumberHasError, setAccountNumberHasError] = useState(Boolean);
  const completedAccordionRoute = useStore.useAccordionRouteCompletedArray();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const setSwitchLayout = useStore.useSetSwitchLayout();
  const offerEnabled = useStore.useOfferEnabled();
  const fullAmountPayment = useStore.useFullAmountPayment();
  const setTrackAndTraceUrl = useStore.useSetTrackAndTraceUrl();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validationRegNumb = useMemo(() => {
    if (regNumber !== undefined && regNumberHasError) {
      return false;
    } else if (regNumberSucces) {
      return true;
    }
  }, [regNumber, regNumberHasError, regNumberSucces]);

  const validationAccNumb = useMemo(() => {
    if (accountNumber !== undefined && accountNumberHasError) {
      return false;
    } else if (accountNumberSucces) {
      return true;
    }
  }, [accountNumber, accountNumberHasError]);

  const nextStepAllowed = useMemo(() => {
    if (payload.paymentFrequency && regNumber && accountNumber && !accountNumberHasError && !regNumberHasError) {
      return true;
    } else {
      return false;
    }
  }, [regNumber, accountNumber]);

  
  return (
    <Accordion
      title="6. Betaling"
      step={AccordionRoutesEnum.PaymentStep}
      onNext={async () => {

        setPayload({ regNo: regNumber });
        setPayload({ accountNo: accountNumber });

        const accountDetails = {
          regNo: regNumber,
          accountNo: accountNumber,
        };

        const response = await postEnrollment({
          ...payload,
          ...accountDetails,
        });
      
        setTrackAndTraceUrl(response.data.url);
    
        // Add tracking
        Analytics.getInstance().trackPurchase(payload, offerEnabled, fullAmountPayment, TrackingFlowTypeEnum.Vertical);
        Analytics.getInstance().trackStepCount(completedAccordionRoute.length, TrackingFlowTypeEnum.Vertical);
        Analytics.getInstance().trackStep("ThankYouStep", TrackingFlowTypeEnum.Vertical, payload);
        Analytics.getInstance().trackAllConsent("collected", payload.consents, TrackingFlowTypeEnum.Vertical);

        setCompletedAccordionRoute(AccordionRoutesEnum.PaymentStep);
        setSwitchLayout(VisibleLayoutEnum.ThankYou);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      stepComplete={nextStepAllowed}
      previousStep={AccordionRoutesEnum.ConsentStep}
      headerImg="/assets/images/wallet-outline.svg"
    >
      <Container>
        <Question>Hvor ofte ønsker du at betale?</Question>
        <ButtonContainer>
          <Button bgColor={ButtonBackgroundColorType.Blue} blockButton={true} onClick={() => setPayload({ paymentFrequency: PaymentFrequencyTypeEnum.Monthly })} isChecked={payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly}>
            Pr. måned
          </Button>
          <Button bgColor={ButtonBackgroundColorType.Blue} blockButton={true} onClick={() => setPayload({ paymentFrequency: PaymentFrequencyTypeEnum.Quarterly })} isChecked={payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly}>
            Pr. kvartal
          </Button>
        </ButtonContainer>
        <InputRow>
          <ColReg>
            <InputVF
              id="paymentstep_regNumb"
              name="payStep_regNumb"
              type={InputTypeEnum.text}
              labelText="Reg nr."
              placeholder="Reg. nr"
              autocomplete="on"
              onChange={(e) => {
                setRegNumber(e.currentTarget.value);
                const reg = /^[0-9]{4}$/;
                if (!reg.test(e.currentTarget.value)) {
                  setRegNumberHasError(true);
                } else if (reg.test(e.currentTarget.value)) {
                  setRegNumberHasError(false);
                  setRegNumberSucces(true);
                }
              }}
              maxlength={4}
              validated={validationRegNumb}
              step={AccordionRoutesEnum.PaymentStep}
            />
          </ColReg>
          <ColAccNumb>
            <InputVF
              id="paymentstep_AccountNumb"
              name="payStep_AccNumb"
              type={InputTypeEnum.text}
              labelText="Kontonummer"
              placeholder="Kontonummer"
              autocomplete="cc-number"
              maxlength={10}
              onChange={(e) => {
                setAccountNumber(e.currentTarget.value);
                const reg = /[0-9]{10}$/;
                if (!reg.test(e.currentTarget.value)) {
                  setAccountNumberHasError(true);
                } else if (reg.test(e.currentTarget.value)) {
                  setAccountNumberHasError(false);
                  setAccountNumberSucces(true);
                }
              }}
              validated={validationAccNumb}
              step={AccordionRoutesEnum.PaymentStep}
            />
          </ColAccNumb>
        </InputRow>
        <Reciept info="Din betaling tilknyttes Betalingsservice." basePrice={false} />
      </Container>
     <Modal showModal={showErrorModal} modalText={t?.summaryStep.errorMessage ?? ""} modalBlock={false} onClose={() => setShowErrorModal(false)}></Modal>
    </Accordion>
  );
}
