import { useEffect, useState } from "react";
import GiftCardPopup from "../../../../components/GiftCardPopup";
import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { fetchGiftCard } from "../../../../utils/api";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import { getCookie, setCookie } from "../../../../utils/cookieService";


export default function OccupationStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const useResetPayload = useStore.useResetPayload();
  const [source, setSource] = useState<string>();

  //payload is reset when a new occupation is selected and therefore we need to fetch the source again
  useEffect(() => { 
    const form = document.querySelector(`[data-component='form-enrollment']`) as HTMLElement;
    const source = form?.dataset.source ?? "";
    setSource(source);
  }, [source])

  const exponeaCookie = () => {
    const cookie = getCookie("__exponea_etc__");
    if (cookie) {
      setPayload({ cookie: cookie });
    }
  }
  const preselectProducts = () => {
    const product = t?.general.preselectUnion
    exponeaCookie();

    if (payload.occupation === "Employee") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsSalaryInsurance: false,
          wantsHRJura: false,
          wantsBasic: false,
          wantsUnion: (product && product === "familyUnion") ? false : true,
          wantsFamilyUnion: (product && product === "familyUnion") ? true : false,
        });
    } else if (payload.occupation === "Unemployed") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsSalaryInsurance: false,
          wantsUnion: (product && product === "familyUnion") ? false : true,
          wantsHRJura: false,
          wantsBasic: false,
          wantsFamilyUnion: (product && product === "familyUnion") ? true : false,
        });
    } else if (payload.occupation === "SelfEmployed") {
      setPayload({
        wantsUnemploymentBenefits: true,
        wantsUnion: false,
        wantsSalaryInsurance: false,
        wantsHRJura: true,
        wantsBasic: true,
        wantsFamilyUnion: false,
      });
    } else if (payload.occupation === "Student") {
        setPayload({
          wantsUnemploymentBenefits: true,
          wantsUnion: true,
          wantsSalaryInsurance: false,
          wantsHRJura: false,
          wantsBasic: false,
          wantsFamilyUnion: false,
        }); 
    }
  };

  return (
    <StepContainer
      stepTitle={t?.occupationStep.title}
      progress={0}
      buttonText={t?.occupationStep.buttonLabel}
      helpText={t?.occupationStep.helpText}
      onNext={() => {
        preselectProducts();
        setRouteStack(StepRoutes.ProductStep, payload);
      }}
      hideBackButton
    >
      <AseFieldset>
        <AseList>
          <RadioButton
            onChange={() => {
              useResetPayload();
              setPayload({ source: source, occupation: "Employee"});
              exponeaCookie();
            }}
            id="Employee"
            checked={payload.occupation === "Employee"}
          >
            {t?.occupationStep.employed}
          </RadioButton>

          <RadioButton
            onChange={() => {
              useResetPayload();
              setPayload({ source: source, occupation: "Unemployed"});
              exponeaCookie();
            }}
            id="Unemployed"
            checked={payload.occupation === "Unemployed"}
          >
            {t?.occupationStep.unemployed}
          </RadioButton>

          <RadioButton
            onChange={() => {
              useResetPayload();
              setPayload({ source: source, occupation: "SelfEmployed"});
              exponeaCookie();
            }}
            id="SelfEmployed"
            checked={payload.occupation === "SelfEmployed"}
          >
            {t?.occupationStep.selfEmployed}
          </RadioButton>
          <RadioButton
            onChange={() => {
              useResetPayload();
              setPayload({ source: source, occupation: "Student"});
              exponeaCookie();
            }}
            id="Student"
            checked={payload.occupation === "Student"}
          >
            {t?.occupationStep.student}
          </RadioButton>
        </AseList>
      </AseFieldset>
    </StepContainer>
  );
}
