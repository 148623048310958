import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";
import { FaCheckCircle } from "react-icons/fa";
import { TbExclamationMark } from "react-icons/tb";

const getBorderAndBackground = (validated: boolean|undefined, selectedOption: string|undefined) => {
    if(validated !== undefined && !validated) {
    return `
        border: 1px solid var(--color-red);
        background-color: var(--color-red-50);
        outline: 1px solid var(--color-red);
        &:focus-visible {
            outline: 1px solid var(--color-red);
        }
    `;
    } else if(validated !== undefined && validated && selectedOption !== undefined){
    return `
        border: 1px solid var(--color-green);
        background-color: var(--color-green-50);
        outline: 1px solid var(--color-green);
        &:focus-visible {
            outline: 1px solid var(--color-green);
        }
    `;
    } else {
    return `
        border: 1px solid var(--color-grey-100);
        background-color: #fff;
    `;
    }
  };

export const Label = styled.label`
    font-size: var(--font-size-body);
    text-transform: none;
    display: block;
    margin-bottom: 8px;
`;

export const Select = styled.select<{validated: boolean, selectedOption: string | undefined}>`
    margin-bottom: 0px;
    width: 100%;
    border-radius: 8px;
    padding: 11px 16px;
    &:focus-visible {
        outline: 2px solid var(--color-petrol);
    }
    &:hover {
        outline: 2px solid var(--color-petrol);
    }
    ${({ validated, selectedOption }) => getBorderAndBackground(validated, selectedOption)}
`;

export const InputErrorMessage = styled.p`
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 2px;
    color: var(--color-red);
`;