import styled from "styled-components";
import { BiCheck } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { devices } from "../../FormEnrollmentNew.styles";

export const Container = styled.div<{ showContactMe: boolean }>`
  display: ${({ showContactMe }) => (!showContactMe ? "none" : "block")};
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  width: 100%;
  min-height: -webkit-fill-available;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 100vh !important;
  overflow-y: scroll;
  p {
    margin-bottom: 0px;
  }
`;

export const ModalContent = styled.div`
  margin: 30px auto 30px auto;
  padding: 20px;
  background-color: #f6f6f6;
  border: 1px solid #888;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.36) 2px 2px 10px 5px;
  width: 95%;
  @media ${devices.laptop} {
    width: 70%;
  }
  @media ${devices.laptopLdesktop} {
    width: 45%;
  }
`;

export const Close = styled.div`
  display: flex;
  justify-content: end;
`;

export const CloseButton = styled.button`
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  background-color: var(--color-orange);
  &:hover {
    background-color: #c86728;
  }
`;

export const CloseIcon = styled(IoClose)`
  color: #ffffff;
  width: 20px;
  height: 20px;
`;

export const ModalBody = styled.div`
  margin-left: auto; 
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  @media ${devices.tablet} {
    width: 80%;
  }
  @media ${devices.laptop} {
    width: 80%;
  }
  @media ${devices.laptopLdesktop} {
    width: 80%;
  }
`;

export const Headline = styled.h2`
  font-size: var(--font-size-header-medium);
  margin-bottom: 20px;
`; 

export const Description = styled.div`
  font-size: 1em;
  margin-bottom: 35px;
`;

export const Navigation = styled.div`
  display: flex; 
  justify-content: space-between;
  gap: 10px;
`;

export const Consent = styled.p`
  font-size: 1em;
  margin-bottom: 12px !important;
  padding-top: 8px;
`;

export const ConsentLink = styled.a`
  text-decoration: underline;
`;
