import { useEffect, useRef, useState } from "react";
import { Content, ContentInner, Header, Title, Wrapper } from "./VFAccordionContainer.styles";
import { FaCheck } from "react-icons/fa";
import { useWindowResize } from "../../hooks/useWindowResize";

export enum AccordionState {
  COMPLETED = "completed",
  IN_PROGRESS = "in-progress",
  UNCOMPLETED = "uncompleted",
}

interface Props {
  title: string;
  state: AccordionState;
  children: React.ReactNode;
}

export default function VFAccordionContainer({ children, state, title }: Props) {
  const contentInnerRef = useRef<HTMLDivElement>(null);
  const expandedHeight = useRef<number>(0);
  const [height, setHeight] = useState(0);
  const windowSize = useWindowResize();

  useEffect(() => {
    if (contentInnerRef.current) {
      expandedHeight.current = contentInnerRef.current?.getBoundingClientRect().height;
    }
  }, [windowSize]);

  useEffect(() => {
    if (state === AccordionState.IN_PROGRESS) {
      setHeight(expandedHeight.current);
    } else {
      setHeight(0);
    }
  }, [state, expandedHeight]);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {state === AccordionState.COMPLETED && <FaCheck />}
      </Header>
      <Content height={height}>
        <ContentInner ref={contentInnerRef}>{children}</ContentInner>
      </Content>
    </Wrapper>
  );
}
