import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import { ButtonBackgroundColorType } from "../../components/Button/Button";
import { Container, HeighlightSpan, Paragraph, Question, WrapperSelect } from "./CircumstanceStep.style";
import { useStore } from "../../../../store/store";
import { useEffect, useMemo, useState } from "react";
import SelectVF from "../../components/SelectVF";
import ContactMeStep from "../ContactMeStep";
import Analytics from "../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import InputVF from "../../components/InputVF";
import { InputTypeEnum } from "../../components/InputVF/InputVF";

export default function CircumstanceStep() {
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const setAccordionRoute = useStore.useSetAccordionRoute();
  const setCompletedAccordionRoute = useStore.useSetCompletedAccordionRoute();
  const unimploymentInsuranceData = useStore.useUnimploymentInsuranceOptions();
  const unionData = useStore.useUnionOptions();
  const translations = useStore.useTranslations();
  const [showContactMe, setShowContactMe] = useState(false);
  const [error, setError] = useState(true);

  useMemo(() => {
    setPayload({haveYouEveryHadDanishUnemploymentInsurance: undefined})
  }, [])

  const unimploymentInsurenceOptions = () => {
    unimploymentInsuranceData.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return unimploymentInsuranceData;
  };

  const unionOptions = () => {
    unionData.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return unionData;
  };

  const canUnsubscribe = useMemo(() => {
    const union = unionData.find((o) => o.id === payload.currentUnion);

    if (union === null || union === undefined) {
      return true;
    } else if (union?.userUnsubscribe) {
      return true;
    } else {
      return false;
    }
  }, [payload.currentUnion, payload.currentlyMemberOfAUnion]);

  const wantsUnsubscribe = useMemo(() => {
    const union = unionData.find((o) => o.id === payload.currentUnion);

    if ((union !== null || union === undefined) && union?.userUnsubscribe) {
      return true;
    } else {
      return false;
    }
  }, [payload.currentUnion, payload.currentlyMemberOfAUnion]);

  useEffect(() => {
    if (!canUnsubscribe) {
      setPayload({ unsubscribeCurrentUnion: false });
    }
  }, [canUnsubscribe, payload.currentUnion, payload.currentlyMemberOfAUnion]);

  //Checking if there is a matching union for choosen unemploymentBenfits and setting union
  useEffect(() => {
    if (payload.currentUnemploymentBenefits) {
      const option = unimploymentInsurenceOptions().find((o) => o.id === payload.currentUnemploymentBenefits);
      const relatedUnion = option?.related;

      if (relatedUnion) {
        const unionOption = unionOptions().find((o) => o.id === relatedUnion);

        if (payload.wantsFamilyUnion || payload.wantsUnion) {
          setPayload({ currentUnion: unionOption?.id });
        } else {
          setPayload({ currentUnion: undefined });
        }
      }
    }
  }, [payload.currentUnemploymentBenefits, payload.wantsFamilyUnion, payload.wantsUnion]);
  //Checking matching union end

  //Validation CVR 
  const validationCVR = () => {
    if (payload.cvrNo !== undefined && !error) {
      return true;
    } else if (payload.cvrNo !== undefined && error) {
      return false;
    }
  };
  //Validation CVR end


  //Track questions showed
  const setTracking = (question: string) => {
    Analytics.getInstance().trackCircumstanceStep(question, "circumstance_questions");
  }
  //End tracking

  
  const nextStepAllowed = useMemo(() => {
    //If only the product "a-kasse" is choosen
    if (payload.wantsUnemploymentBenefits && !payload.wantsUnion && !payload.wantsFamilyUnion && !payload.wantsBasic && !payload.wantsHRJura) {
        if (payload.currentlyHasUnemploymentBenefits === undefined) {
          return false;
        }
        if (payload.currentlyHasUnemploymentBenefits && payload.currentUnemploymentBenefits !== undefined) {
          return true;
        }
        if ((!payload.currentlyHasUnemploymentBenefits && payload.haveYouEveryHadDanishUnemploymentInsurance !== undefined) && ((!payload.haveYouEveryHadDanishUnemploymentInsurance) || (payload.haveYouEveryHadDanishUnemploymentInsurance && payload.currentUnemploymentBenefits !== undefined))) { 
          return true
        }
        if (!payload.currentlyHasUnemploymentBenefits && payload.haveYouEveryHadDanishUnemploymentInsurance && payload.currentUnemploymentBenefits !== undefined) {
          return true
        }

        return false;
      //If the product "HR-Jura" or "Basis" is choosen  
    } else if (payload.wantsBasic || payload.wantsHRJura) {
        if (payload.currentlyHasUnemploymentBenefits === undefined) {
          return false;
        }
        if (payload.currentlyHasUnemploymentBenefits && payload.currentUnemploymentBenefits !== undefined && (payload.doYouRunYourOwnBusiness !== undefined && (payload.doYouRunYourOwnBusiness && payload.cvrNo !== undefined || payload.doYouRunYourOwnBusiness === false))) {
          return true;
        }
        if ((payload.doYouRunYourOwnBusiness !== undefined && (payload.doYouRunYourOwnBusiness && payload.cvrNo !== undefined || payload.doYouRunYourOwnBusiness === false)) && (!payload.currentlyHasUnemploymentBenefits && payload.haveYouEveryHadDanishUnemploymentInsurance !== undefined && ((!payload.haveYouEveryHadDanishUnemploymentInsurance) || (payload.haveYouEveryHadDanishUnemploymentInsurance && payload.currentUnemploymentBenefits !== undefined)))) { 
          return true
        }
        if ((payload.doYouRunYourOwnBusiness !== undefined && (payload.doYouRunYourOwnBusiness && payload.cvrNo !== undefined || payload.doYouRunYourOwnBusiness === false)) && (!payload.currentlyHasUnemploymentBenefits && payload.haveYouEveryHadDanishUnemploymentInsurance && payload.currentUnemploymentBenefits !== undefined)) {
          return true
        }

        return false;
      //if only the product "union" is choosen
    } else if (((payload.wantsUnion || payload.wantsFamilyUnion) && !payload.wantsUnemploymentBenefits)) {
        if (payload.currentlyMemberOfAUnion === undefined) {
          return false;
        }
        if (!payload.currentlyMemberOfAUnion || (payload.currentlyMemberOfAUnion && payload.currentUnion !== undefined)) {
          return true;
        }
        return false;
      //If both products are choosen
    } else if ((payload.wantsUnemploymentBenefits && (payload.wantsUnion || payload.wantsFamilyUnion))) {
        if (payload.currentlyHasUnemploymentBenefits === undefined || payload.currentlyMemberOfAUnion === undefined) {
          return false;
        }
        if (!payload.currentlyHasUnemploymentBenefits && !payload.currentlyMemberOfAUnion) {
          return true;
        }
        if (payload.currentlyHasUnemploymentBenefits && payload.currentUnemploymentBenefits !== undefined && payload.currentlyMemberOfAUnion === undefined) {
          return false
        }
        if ((!payload.currentlyHasUnemploymentBenefits || (payload.currentlyHasUnemploymentBenefits && payload.currentUnemploymentBenefits !== undefined)) && (!payload.currentlyMemberOfAUnion || (payload.currentlyMemberOfAUnion && payload.currentUnion !== undefined))) {
          return true;
        }
        return false;
        //Fallback false
    } else {
      return false;
    }
  }, [payload]);


  const next = () => {
    if(payload.occupation === "Unemployed" && payload.areYouCurrentlyReceivingUnemploymentBenefits === true) {
      setAccordionRoute(AccordionRoutesEnum.InformationStep, payload);
    } else if((payload.occupation === "Unemployed" && (payload.wantsUnemploymentBenefits && !payload.wantsFamilyUnion && !payload.wantsUnion)) || (payload.occupation === "Unemployed" && ((payload.wantsFamilyUnion || payload.wantsUnion) && payload.wantsUnemploymentBenefits)) || (payload.occupation === "SelfEmployed" && payload.doYouRunYourOwnBusiness === false)) {
      if(payload.occupation === "Unemployed" && !payload.currentlyHasUnemploymentBenefits) {
        setPayload({ cause: "Ledig har ikke en a-kasse" });
      }
      if(payload.occupation === "SelfEmployed") {
        setPayload({ cause: "Selvstændig men ikke på fuldtid" });
      }
      setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
      setShowContactMe(true); 
    } else if (payload.occupation === "Unemployed" && payload.areYouCurrentlyReceivingUnemploymentBenefits === false) {
      setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
      setPayload({ cause: "Ledig modtager ikke dagpenge" });
      setShowContactMe(true);
    } else if (payload.occupation !== "Unemployed" && payload.areYouPrimarilyWorkingInDenmark) {
      setAccordionRoute(AccordionRoutesEnum.InformationStep, payload);
    } else if (payload.occupation !== "Unemployed" && !payload.areYouPrimarilyWorkingInDenmark) {
      setAccordionRoute(AccordionRoutesEnum.ContactMeStep, payload);
      setPayload({ cause: "Bor i udlandet" });
      setShowContactMe(true);
    }
  };

  const closeModal = () => {
    setAccordionRoute(AccordionRoutesEnum.CircumstanceStep, payload);
    setShowContactMe(false);
  }

  return (
    <>
      <Accordion
        title="3. Din situation"
        step={AccordionRoutesEnum.CircumstanceStep}
        onNext={() => {
          // Add tracking
          if (payload.currentlyHasUnemploymentBenefits) {
            Analytics.getInstance().trackTerminateUnemploymentBenefits(TrackingFlowTypeEnum.Vertical);
          }
          if (payload.unsubscribeCurrentUnion) {
            Analytics.getInstance().trackTerminateUnion(TrackingFlowTypeEnum.Vertical);
          }
          next();
          setCompletedAccordionRoute(AccordionRoutesEnum.CircumstanceStep);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        stepComplete={nextStepAllowed}
        previousStep={AccordionRoutesEnum.ProductStep}
        headerImg="/assets/images/ar-on-you-outline.svg"
      >
        <Question>{translations?.workingDenmarkStep.title}</Question>
        <Container>
          <Button onClick={() => setPayload({ areYouPrimarilyWorkingInDenmark: true })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.areYouPrimarilyWorkingInDenmark === true}>
            {translations?.general.answerYes}
          </Button>
          <Button onClick={() => setPayload({ areYouPrimarilyWorkingInDenmark: false })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.areYouPrimarilyWorkingInDenmark === false}>
            {translations?.general.answerNo}
          </Button>
        </Container>
        {(payload.occupation === "SelfEmployed" && (payload.wantsBasic || payload.wantsHRJura)) && (
          <>
            <Question>Er du selvstændig på fuldtid?</Question>
            <Container>
              <Button 
                bgColor={ButtonBackgroundColorType.Blue} 
                blockButton={true} 
                isChecked={payload.doYouRunYourOwnBusiness && payload.doYouRunYourOwnBusiness !== undefined} 
                onClick={() => {
                  setPayload({ doYouRunYourOwnBusiness: true })
                  setTracking("Indtast dit CVR-nummer")
                }}
              >
                Ja
              </Button>
              <Button 
                bgColor={ButtonBackgroundColorType.Blue} 
                blockButton={true} 
                isChecked={!payload.doYouRunYourOwnBusiness && payload.doYouRunYourOwnBusiness !== undefined} 
                onClick={() => setPayload({ doYouRunYourOwnBusiness: false, cvrNo: undefined })}
              >
                Nej
              </Button> 
            </Container>
          </>
        )}
        {payload.doYouRunYourOwnBusiness && (
          <>
            <WrapperSelect>
              <InputVF
                id="CVR" 
                name="CVRinput" 
                type={InputTypeEnum.text} 
                labelText="CVR-nummer" 
                placeholder="Indtast dit CVR-nummer"
                autocomplete="on" 
                onChange={(e) => {
                  setPayload({ cvrNo: e.currentTarget.value });
                  const reg = /[0-9]{8}/
                  if (!reg.test(e.currentTarget.value)) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                }}
                maxlength={8}
                validated={validationCVR()} 
                step={AccordionRoutesEnum.ProductStep} 
              />
            </WrapperSelect>
          </>  
        )}
        {payload.wantsUnemploymentBenefits && (
          <>
            <Question>{translations?.unemploymentInsuranceStep.title}</Question>
            <Container>
              <Button 
                onClick={() => {
                  setPayload({ currentlyHasUnemploymentBenefits: true })
                  setTracking("Hvilken nuværende akasse")
                  if(payload.occupation === "Unemployed") {
                    setTracking("Modtager du dagpenge") 
                  }
                }} 
                bgColor={ButtonBackgroundColorType.Blue} 
                blockButton={false} 
                isChecked={payload.currentlyHasUnemploymentBenefits === true}
              >
                  {translations?.general.answerYes}
              </Button>
              <Button 
                onClick={() => {
                  setPayload({ currentlyHasUnemploymentBenefits: false, currentUnemploymentBenefits: undefined })
                  setTracking("Har du tidligere været medlem af en a-kasse")
                }} 
                bgColor={ButtonBackgroundColorType.Blue} 
                blockButton={false} 
                isChecked={payload.currentlyHasUnemploymentBenefits === false}
              >
                  {translations?.general.answerNo}
              </Button>
            </Container>
            {payload.currentlyHasUnemploymentBenefits && (
              <WrapperSelect>
                <SelectVF
                  id="chooseCurrentUnemploymentInscurance"
                  name="unemploymentInscurance"
                  options={unimploymentInsurenceOptions()}
                  placeholder="Vælg nuværende a-kasse"
                  label="Hvilken a-kasse?"
                  selectedOption={payload.currentUnemploymentBenefits}
                  step={AccordionRoutesEnum.CircumstanceStep}
                  onSelect={(id) => {
                    setPayload({ currentUnemploymentBenefits: id });
                  }}
                />
                <Paragraph>{translations?.unemploymentInsuranceStep.description}</Paragraph>
              </WrapperSelect>
            )}
            {(payload.occupation === "Unemployed" && payload.currentlyHasUnemploymentBenefits) && (
              <>
                <Question>{translations?.receivingUnemploymentBenefitsStep.title}</Question>
                <Container>
                  <Button onClick={() => setPayload({ areYouCurrentlyReceivingUnemploymentBenefits: true })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.areYouCurrentlyReceivingUnemploymentBenefits === true}>
                    {translations?.general.answerYes}
                  </Button>
                  <Button onClick={() => setPayload({ areYouCurrentlyReceivingUnemploymentBenefits: false })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.areYouCurrentlyReceivingUnemploymentBenefits === false}>
                    {translations?.general.answerNo}
                  </Button>
                </Container>
              </>
            )}
            {(!payload.currentlyHasUnemploymentBenefits && payload.currentlyHasUnemploymentBenefits !== undefined) && (
              <>
               <Question>{translations?.fitWithYouStep.priorHadUnemploymentInsurance}</Question>
               <Container>
                 <Button 
                    onClick={() => {
                      setPayload({ haveYouEveryHadDanishUnemploymentInsurance: true })
                      setTracking("Hvilken tidligere akasse")
                    }} 
                    bgColor={ButtonBackgroundColorType.Blue} 
                    blockButton={false} 
                    isChecked={payload.haveYouEveryHadDanishUnemploymentInsurance === true}
                  >
                   {translations?.general.answerYes}
                 </Button>
                 <Button 
                    onClick={() => {
                      setPayload({ haveYouEveryHadDanishUnemploymentInsurance: false,  currentUnemploymentBenefits: undefined  })
                    }} 
                    bgColor={ButtonBackgroundColorType.Blue} 
                    blockButton={false} 
                    isChecked={payload.haveYouEveryHadDanishUnemploymentInsurance === false}
                  >
                   {translations?.general.answerNo}
                 </Button>
               </Container>
              </>
            )}
            {(payload.haveYouEveryHadDanishUnemploymentInsurance && !payload.currentlyHasUnemploymentBenefits) && (
              <WrapperSelect>
                <SelectVF
                  id="choosePreviousUnemploymentInscurance"
                  name="previousUnemploymentInscurance"
                  options={unimploymentInsurenceOptions()}
                  placeholder="Vælg tidligere a-kasse"
                  label="Hvilken a-kasse?"
                  selectedOption={payload.currentUnemploymentBenefits}
                  step={AccordionRoutesEnum.CircumstanceStep}
                  onSelect={(id) => {
                    setPayload({ currentUnemploymentBenefits: id, haveYouEveryHadDanishUnemploymentInsurance: true});
                  }}
                />
              </WrapperSelect>
            )}
          </>
        )}
        {(payload.wantsUnion || payload.wantsFamilyUnion) && (
          <>
            <Question>{translations?.unionStep.title}</Question>
            <Container>
              <Button onClick={() => {
                  setPayload({ currentlyMemberOfAUnion: true })
                  setTracking("Hvilken fagforening")
                }} 
                bgColor={ButtonBackgroundColorType.Blue} 
                blockButton={false} 
                isChecked={payload.currentlyMemberOfAUnion === true}
              >
                {translations?.general.answerYes}
              </Button>
              <Button onClick={() => setPayload({ currentlyMemberOfAUnion: false,  currentUnion: undefined})} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.currentlyMemberOfAUnion === false}>
                {translations?.general.answerNo}
              </Button>
            </Container>
            {payload.currentlyMemberOfAUnion && (
              <>
                <WrapperSelect>
                  <SelectVF
                    id="chooseCurrentUnion"
                    name="union"
                    options={unionOptions()}
                    defaultOption={payload.currentUnion === undefined ? "Vælg fagforening" : unionOptions().find((p) => p.id === payload.currentUnion)?.name}
                    label="Hvilken fagforening?"
                    selectedOption={payload.currentUnion}
                    step={AccordionRoutesEnum.CircumstanceStep}
                    onSelect={(id) => {
                      setPayload({ currentUnion: id });
                    }}
                  />
                  {!canUnsubscribe && (
                    <Paragraph>
                      <HeighlightSpan>OBS!</HeighlightSpan> Vi kan desværre ikke flytte dig automatisk, da din fagforening ikke tillader det. Du skal derfor selv sørge for at opsige din nuværende fagforening.
                    </Paragraph>
                  )}
                </WrapperSelect>
                {wantsUnsubscribe && (
                  <>
                    <Question>Skal vi opsige fagforening for dig?</Question>
                    <Container>
                      <Button onClick={() => setPayload({ unsubscribeCurrentUnion: true })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.unsubscribeCurrentUnion === true}>
                        {translations?.general.answerYes}
                      </Button>
                      <Button onClick={() => setPayload({ unsubscribeCurrentUnion: false })} bgColor={ButtonBackgroundColorType.Blue} blockButton={false} isChecked={payload.unsubscribeCurrentUnion === false}>
                        {translations?.general.answerNo}
                      </Button>
                    </Container>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Accordion>

      <ContactMeStep showContactMe={showContactMe} onClose={closeModal} />
    </>
  );
}
