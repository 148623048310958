import { TbCircleCheck } from "react-icons/tb";
import styled from "styled-components";
import { AccordionStateEnum } from "./Accordion";
import { devices } from "../../FormEnrollmentNew.styles";

const getBackgroundColor = (state: AccordionStateEnum) => {
  switch (state) {
    case AccordionStateEnum.Completed:
      return `
            background-color: rgba(255, 255, 255, 0.50);
            cursor: pointer;
            `;
    case AccordionStateEnum.Active:
      return `
            background-color: #fff;
            `;
    case AccordionStateEnum.Inactive:
      return `
            background-color: #fff;
            `;
  }
};

export const Wrapper = styled.div<{ state: AccordionStateEnum; }>`
  scroll-margin-top: 800px;
  box-shadow: 0px 0px 10px 2px rgba(1, 61, 68, 0.1);
  border-radius: 12px;
  margin-bottom: 8px;
  ${({ state }) => getBackgroundColor(state)}
`;

export const Header = styled.div<{ state: AccordionStateEnum }>`
  padding: 20px;
  @media ${devices.laptop} {
    padding: 23px 30px;
  }
  width: 100%;
  text-transform: initial;
  font-weight: ${({ state }) => (state === "Active" ? "600" : "normal")};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivInHeader = styled.div`
  display: flex;
`;

export const HeaderIcon = styled.img`
  margin-right: 8px;
  width: 22px;
  display: inline;
`;

export const Title = styled.p`
  font-size: var(--font-size-body);
  display: inline;
  margin-bottom: 0px;
`;

export const Content = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`;

export const ContentInner = styled.div`
  padding: 0px 20px 20px 20px;
  @media ${devices.laptop} {
    padding: 0px 30px 30px 30px;
  }
`;

export const CompletedIcon = styled(TbCircleCheck)`
  color: #ffffff;
  fill: var(--color-blue-700);
  display: inline;
  float: right;
  height: 26px;
  width: 26px;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media ${devices.laptop} {
    width: 60%;
  }
`;

export const CampaignImg = styled.img`
  height: auto;
  max-width: 100%;
  margin-bottom: -4px;
`;
