import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";

export const WrapperSelect = styled.div`
  margin-bottom: 30px;
`;

export const Question = styled.p`
    font-size: var(--font-size-body);
    margin-bottom: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
  @media ${devices.laptop} {
    gap: 16px;
    flex-direction: row;
  }
`;

export const Paragraph = styled.div`
  font-size: 13px;
  margin: 10px 0px 30px 0px;
`;

export const HeighlightSpan = styled.span`
  color: var(--color-red);
  font-weight: 600;
  text-transform: capitalize;
`;