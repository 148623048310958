import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../store/store";
import { PaymentFrequencyTypeEnum } from "../../../../types/PaymentFrequencyTypeEnum";
import { formatNumber } from "../../../../utils/formatNumber";
import { Container, Description, Headline, Info, Product, ProductFree, ProductFreeExtra, ProductRow, Sumup, SumupText, TopLine } from "./Reciept.style";

interface Props {
  info?: string;
  basePrice: boolean;
}

export default function Reciept({ info, basePrice }: Props) {
  const payload = useStore.usePayload();
  const offerEnabled = useStore.useOfferEnabled();
  const translations = useStore.useTranslations();
  const setFullAmountPayment = useStore.useSetFullAmountPayment();
  const bsPaymentFee = useStore.useBsPaymentFee();

 /*  
Check for BSpaymentFee moved to previous step YourConsentStep because of OI splittest
 const [bsPaymentFee, setBsPaymentFee] = useState(0);

 useEffect(() => {
    if (payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly && payload.wantsUnemploymentBenefits) {
      console.log("1")
      setBsPaymentFee(Number(translations?.paymentStep.bs.quarterlyServiceFee))
    } else if (payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly && payload.wantsUnemploymentBenefits) {
      console.log("2")
      setBsPaymentFee(Number(translations?.paymentStep.bs.monthlyServiceFee))
    } else {
      console.log("3")
      setBsPaymentFee(0)
    }
  }, [payload.paymentFrequency, payload.wantsUnemploymentBenefits]);

  console.log("bsPaymentFee: ", bsPaymentFee) */

  //Price of unemploymentBenefits pr. month based on occupation (if the product is not choosen it returns 0)
  const unemploymentInsurancePrice = (payload.wantsUnemploymentBenefits && payload.occupation !== "Student" && payload.occupation !== "SelfEmployed") ? Number(translations?.summaryStep.unemploymentInsurance.price) : payload.wantsUnemploymentBenefits && payload.occupation === "SelfEmployed" ? Number(translations?.productsStep.unemploymentInsurance.price_selfemployed) : 0;

  //Price of union pr.month (if the product is not choosen it returns 0)
  let unionPrice: number;
  if(payload.wantsUnion) {
    if(offerEnabled && payload.wantsUnemploymentBenefits && payload.occupation !== "Student") {
      unionPrice = 0;
    } else if ((offerEnabled && !payload.wantsUnemploymentBenefits) || !offerEnabled || (offerEnabled && payload.occupation === "Student")) {
      unionPrice = Number(translations?.summaryStep.union.price);
    }
  } else {
    unionPrice = 0
  }

  //Price of union pr.month (if the product is not choosen it returns 0)
  let familyUnionPrice: number;
  if(payload.wantsFamilyUnion) {
    if(offerEnabled && payload.wantsUnemploymentBenefits) {
      familyUnionPrice = 0;
    } else if((offerEnabled && !payload.wantsUnemploymentBenefits) || !offerEnabled) {
      familyUnionPrice = Number(translations?.productsStep.familyUnion.price);
    }
  } else {
    familyUnionPrice = 0
  }

  //Monthly price without BsPaymentFee
  const basicPrice = useMemo(() => {

    const totalAmountPrice = unemploymentInsurancePrice + unionPrice + familyUnionPrice;

    return totalAmountPrice;

  }, [payload, translations]);

  //Full price either monthly or quarterly with possible BsPaymentFee
  const recieptPrice = useMemo(() => {
    let fullPrice = unemploymentInsurancePrice

    if(payload.wantsUnemploymentBenefits) {
      fullPrice = payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly ? (unemploymentInsurancePrice * 3) + bsPaymentFee : unemploymentInsurancePrice + bsPaymentFee;
    }

    if(payload.wantsUnion) {
      fullPrice = payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? (unemploymentInsurancePrice + unionPrice + bsPaymentFee) : ((unemploymentInsurancePrice * 3) + (unionPrice * 3)) + bsPaymentFee;
    }

    if(payload.wantsFamilyUnion) {
      fullPrice = payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? (unemploymentInsurancePrice + familyUnionPrice + bsPaymentFee) : ((unemploymentInsurancePrice * 3) + (familyUnionPrice * 3)) + bsPaymentFee;
    }

    if(payload.wantsHRJura || payload.wantsBasic) {
      fullPrice = payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? (unemploymentInsurancePrice + bsPaymentFee) : (unemploymentInsurancePrice * 3) + bsPaymentFee;
    } 

    return fullPrice

    
  }, [payload])

//Find totalAmount based on const basePrice
const totalAmount = useMemo(() => {
  if(basePrice) {
    return basicPrice;
  } else {
    setFullAmountPayment(recieptPrice)
    return recieptPrice;
  }
}, [basicPrice, recieptPrice, basePrice])

  return (
    <>
      <TopLine />
      <Container>
        <Headline>Du har valgt:</Headline>
        {!payload.wantsUnemploymentBenefits && !payload.wantsSalaryInsurance && !payload.wantsUnion && !payload.wantsFamilyUnion && !payload.wantsBasic && !payload.wantsHRJura && <Description>Du har ikke valgt et produkt.</Description>}
        {payload.wantsUnemploymentBenefits && payload.occupation !== "Student" && (
          <ProductRow>
            <Product>{translations?.productsStep.unemploymentInsurance.title}</Product>
            {(basePrice || payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly) &&
            <Product>
                {payload.occupation === "SelfEmployed" ? `${translations?.productsStep.unemploymentInsurance.price_selfemployed} ${translations?.general.krPerMonth}` : `${translations?.productsStep.unemploymentInsurance.price} ${translations?.general.krPerMonth}`}
            </Product>
            }
           {(!basePrice && payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly) &&
            <Product>
                {payload.occupation === "SelfEmployed" ? `${Number(translations?.productsStep.unemploymentInsurance.price_selfemployed) * 3} ${translations?.general.krPerMonth}` : `${Number(translations?.productsStep.unemploymentInsurance.price) * 3} ${translations?.general.krPerMonth}`}
            </Product>
            }
          </ProductRow>
        )}
        {payload.wantsUnemploymentBenefits && payload.occupation === "Student" && (
          <ProductRow>
            <Product>A-kasse</Product>
            <Product>Kontingent fritaget</Product>
          </ProductRow> 
        )}
        {payload.wantsSalaryInsurance && 
          <ProductRow>
            <Product>Lønsikring</Product>
            <Product>Vi kontakter dig.</Product>
          </ProductRow>
        }
        {payload.wantsUnion && (
          <ProductRow>
            <Product>{translations?.productsStep.union.title}</Product>
            {payload.occupation === "Student" && (
              <Product>{translations?.productsStep.union.price} {translations?.general.krPerMonth}</Product>
            )}
            {(offerEnabled && payload.wantsUnemploymentBenefits && payload.occupation !== "Student") && (              
              <div>
                <ProductFree>Gratis i 6 mdr.</ProductFree>
                <ProductFreeExtra>
                  Herefter: {translations?.productsStep.union.price}
                  {translations?.general.krPerMonth}
                </ProductFreeExtra>
              </div>
            )}
            {(!offerEnabled || (offerEnabled && !payload.wantsUnemploymentBenefits && payload.occupation !== "Student")) && (basePrice || payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly) && 
              <Product>
                {translations?.productsStep.union.price} {translations?.general.krPerMonth}
              </Product>
            }
            {(!offerEnabled || (offerEnabled && !payload.wantsUnemploymentBenefits)) && (!basePrice && payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly) &&
              <Product>
                {Number(translations?.productsStep.union.price) * 3 } {translations?.general.krPerQuarterly}
              </Product>
            }
          </ProductRow>
        )}
        {payload.wantsFamilyUnion && (
          <ProductRow>
            <Product>{translations?.productsStep.familyUnion.title}</Product>
            {(offerEnabled && payload.wantsUnemploymentBenefits && 
              <div>
                <ProductFree>Gratis i 6 mdr.</ProductFree>
                <ProductFreeExtra>
                  Herefter: {translations?.productsStep.familyUnion.price} {translations?.general.krPerMonth}
                </ProductFreeExtra>
              </div>
            )}
            {(!offerEnabled || (offerEnabled && !payload.wantsUnemploymentBenefits)) && (basePrice || payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly) && 
              <Product>
                {translations?.productsStep.familyUnion.price} {translations?.general.krPerMonth}
              </Product>
            }
            {(!offerEnabled || (offerEnabled && !payload.wantsUnemploymentBenefits)) && (!basePrice && payload.paymentFrequency === PaymentFrequencyTypeEnum.Quarterly) &&
              <Product>
                {Number(translations?.productsStep.familyUnion.price) * 3} {translations?.general.krPerQuarterly}
              </Product>
            }
          </ProductRow>
        )}
        {payload.wantsBasic &&
          <ProductRow>
            <Product>{translations?.summaryStep.basic.title}</Product>
            <Product>{translations?.summaryStep.basic.price}</Product>
          </ProductRow>
        }
        {payload.wantsHRJura &&
          <ProductRow>
            <Product>{translations?.summaryStep.hrJura.title}</Product>
            <Product>{translations?.summaryStep.hrJura.price}</Product>
          </ProductRow>
        }
        {(!basePrice && payload.wantsUnemploymentBenefits) &&
          <ProductRow>
            <Product>{translations?.general.fee}</Product>
            <Product>{formatNumber(bsPaymentFee)} {payload.paymentFrequency === PaymentFrequencyTypeEnum.Monthly ? translations?.general.krPerMonth : translations?.general.krPerQuarterly }</Product>
          </ProductRow>
        }
        <Sumup>
          <SumupText>I alt</SumupText>
          <SumupText>{formatNumber(totalAmount)} kr./md.</SumupText>
        </Sumup>
        {info && <Info>Din betaling tilknyttes betalingsservice</Info>}
      </Container>
    </>
  );
}
