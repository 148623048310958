import { useState } from "react";
import { Body, Card, CheckContainer, Checked, Container, ContentContainer, Description, ImageContainer, Img, Offer, Subtitle, Title, Unchecked } from "./ProductCard.style";

interface Props {
  title: string | undefined;
  subtitle?: string | undefined;
  children: React.ReactNode;
  hasDiscount?: boolean;
  isChecked: boolean;
  iconSrc: string;
  opacity?: boolean;
  onClick?: () => void;
}

export default function ProductCard({ title, subtitle, children, isChecked = false, onClick, hasDiscount = false, iconSrc, opacity }: Props) {
  return (
    <Container opacity={opacity}>
      {hasDiscount && <Offer>SUPER TILBUD</Offer>}
      <Card onClick={onClick} isChecked={isChecked}>
        {isChecked && (
          <CheckContainer>
            <Unchecked>
              <Checked />
            </Unchecked>
          </CheckContainer>
        )}
        <Body>
          <ImageContainer>
            <Img isChecked={isChecked} src={iconSrc}></Img>
          </ImageContainer>
          <ContentContainer>
            <Title>{title}</Title>
            <Subtitle isChecked={isChecked}>{subtitle}</Subtitle>
            <Description hasDiscount={hasDiscount}>{children}</Description>
          </ContentContainer>
        </Body>
      </Card>
    </Container>
  );
}
