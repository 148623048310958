import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/20px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background-color: #f3f3f3;
`;
export const TopLine = styled.div`
  box-shadow: 0px 1px 3px 3px #dddddd;
  height: 1px;
  background-color: #d7d7d7;
`;

export const Headline = styled.p`
  font-size: var(--font-size-body);
  font-weight: 600;
  background-color: #f3f3f3;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: var(--font-size-body);
`;

export const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Product = styled.p`
  font-size: var(--font-size-body);
`;

export const ProductFree = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 0px;
  float: right;
`;

export const ProductFreeExtra = styled.p`
  font-size: 12px;
  font-style: italic;
`;

export const Sumup = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
`;

export const SumupText = styled.p`
    font-weight: 600;
    font-size: var(--font-size-body); 
    margin-bottom: 0px;
    padding: 7px;
}
`;

export const Info = styled.p`
  margin: 15px 0px;
  font-size: 14px;
`;
