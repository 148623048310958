import { Store } from "../store";
import { SetState, StateCreator } from "zustand";
import { PayloadModel } from "../../types/PayloadModel";
import StepRoutes from "../../constants/Routes";
import { TranslationModel } from "../../types/TranslationModel";
import { UnemploymentInsuranceOptionModel } from "../../types/UnemploymentInsuranceOptionModel";
import { PaymentTypeEnum } from "../../types/PaymentTypesEnum";
import { UnionOptionModel } from "../../types/UnionOptionModel";
import { PaymentFrequencyTypeEnum } from "../../types/PaymentFrequencyTypeEnum";
import Analytics from "../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../types/TrackingFlowTypeEnum";
import { AccordionRoutesEnum } from "../../types/AccordionRoutes";
import { VisibleLayoutEnum } from "../../features/FormEnrollmentNew/FormEnrollmentNew";

export type FormEnrollmentSlice = {
  payload: PayloadModel;
  translations: TranslationModel | null;
  routeStack: StepRoutes[];
  unionOptions: UnionOptionModel[] | [];
  offerEnabled: boolean;
  contactMe: boolean;
  flowType: TrackingFlowTypeEnum;
  unimploymentInsuranceOptions: UnemploymentInsuranceOptionModel[] | [];
  trackAndTraceUrl: string;
  testUrl: string | undefined;
  setRouteStack: (route: StepRoutes, payload: PayloadModel) => void;
  setBack: () => void;
  setPayload: (data: { [key: string]: string | boolean | undefined | object }) => void;
  setStepRoute: (stepRoute: StepRoutes) => void;
  setTranslation: (config: TranslationModel) => void;
  setUnionOptions: (option: UnionOptionModel[]) => void;
  setOfferEnabled: (offerEnabled: boolean) => void;
  setFlowType: (type: TrackingFlowTypeEnum) => void;
  setUnimploymentInsuranceOptions: (option: UnemploymentInsuranceOptionModel[]) => void;
  resetPayload: () => void;
  setTrackAndTraceUrl: (url: string) => void;
  setTestUrl: (testUrl: string | undefined) => void;
  //New OI
  accordionRouteCompletedArray: AccordionRoutesEnum[];
  accordionRoute: AccordionRoutesEnum;
  showAccordion: boolean;
  showReciept: boolean;
  showCallMeReciept: boolean;
  switchLayout: VisibleLayoutEnum;
  fullAmountPayment: number;
  bsPaymentFee: number
  setCompletedAccordionRoute: (route: AccordionRoutesEnum) => void;
  setAccordionRoute: (route: AccordionRoutesEnum, payload: PayloadModel) => void;
  setSwitchLayout: (visibleLayout: VisibleLayoutEnum) => void;
  setFullAmountPayment: (fullAmountPayment: number) => void;
  setShowOccuptationStep: (showOccuptationStep: boolean) => void; 
  setBsPaymentFee: (bsPaymentFee: number) => void;
  //New OI end
};

export const formEnrollmentInitialState = {
  translations: null,
  routeStack: [StepRoutes.OccupationStep],
  unionOptions: [],
  unimploymentInsuranceOptions: [],
  offerEnabled: false,
  contactMe: false,
  flowType: "standard",
  trackAndTraceUrl: "",
  testUrl: "",
  //New OI  
  accordionRouteCompletedArray: [] as AccordionRoutesEnum[],
  accordionRoute: AccordionRoutesEnum.OccupationStep,
  switchLayout: VisibleLayoutEnum.Accordion,
  fullAmountPayment: 0,
  bsPaymentFee: 0,
  //New OI end
  payload: {
    firstName: "",
    lastName: "",
    addressText: "",
    address: "",
    city: "",
    zipCode: "",
    door: "",
    floor: "",
    houseNumber: "",
    mailAddress: "",
    mobilePhone: "",
    occupation: "Employee",
    signupDate: "2000-01-01",
    settingsNodeKey: "",
    socialSecurityNumber: "",
    wantsSalaryInsurance: false,
    wantsUnemploymentBenefits: false,
    wantsUnion: false,
    wantsHRJura: false,
    wantsFamilyUnion: false,
    wantsBasic: false,
    accountNo: "",
    areYouPrimarilyWorkingInDenmark: true,
    currentlyHasUnemploymentBenefits: undefined,
    currentlyMemberOfAUnion: undefined,
    currentUnemploymentBenefits: undefined,
    currentUnion: undefined,
    doYouRunYourOwnBusiness: undefined,
    haveYouEveryBeenAMemberOfADanishUnion: undefined,
    haveYouEveryHadDanishUnemploymentInsurance: undefined,
    haveYouRecentlyWorkedAbroad: false,
    paymentFrequency: PaymentFrequencyTypeEnum.Monthly,
    paymentType: PaymentTypeEnum.BSPAYMENT,
    regNo: "",
    unsubscribeCurrentUnemploymentBenefits: undefined,
    unsubscribeCurrentUnion: undefined,
    whatTypeOfBusinessAreYouWorkingFor: undefined,
    areYouCurrentlyReceivingUnemploymentBenefits: undefined,
    cvrNo: undefined,
    contactMe: false,
    ownerId: undefined,
    isDanishResident: true,
    consents: {
      unemploymentBenefits: {
        aseCooperation: false,
        member: false,
        partner: false,
      },
      union: {
        aseCooperation: false,
        member: false,
        partner: false,
      },
    },
    source: "",
    affiliate: "",
    cookie: undefined
  },
} as FormEnrollmentSlice;

export const createFormEnrollmentSlice: StateCreator<FormEnrollmentSlice, [], [], FormEnrollmentSlice> = (set) =>
  ({
    ...formEnrollmentInitialState,
    setPayload: (data: { [key: string]: string | boolean | undefined | object }) => {
      set((state: Store) => {
        return {
          ...state,
          payload: {
            ...state.payload,
            ...data,
          },
        };
      });
    },
    setRouteStack: (route: StepRoutes, payload: PayloadModel) => {
      set((state: Store) => {
        // Add tracking
        Analytics.getInstance().trackStep(route, state.flowType, payload);

        return {
          routeStack: [...state.routeStack, route],
        };
      });
    },
    setBack: () => {
      set(({ routeStack }: Store) => {
        const stack = [...routeStack];
        stack.pop();
        return {
          routeStack: stack,
        };
      });
    },
    setTranslation: (translations: TranslationModel) => {
      set((state) => {
        return {
          translations,
          payload: {
            ...state.payload,
            ...translations.payload,
          },
        };
      });
    },
    setUnionOptions: (unionOptions: UnionOptionModel[]) => {
      set(() => {
        return {
          unionOptions,
        };
      });
    },
    setUnimploymentInsuranceOptions: (unimploymentInsuranceOptions: UnemploymentInsuranceOptionModel[]) => {
      set(() => {
        return {
          unimploymentInsuranceOptions,
        };
      });
    },
    setOfferEnabled: (offerEnabled: boolean) => {
      set(() => {
        return {
          offerEnabled,
        };
      });
    },
    setFlowType: (type: TrackingFlowTypeEnum) => {
      set(() => ({
        flowType: type,
      }));
    },
    resetPayload: () => {
      set((state) => {
        return {
          payload: {
            ...formEnrollmentInitialState.payload,
            ...state.translations?.payload,
            settingsNodeKey: state.payload.settingsNodeKey,
          },
        };
      });
    },
    setTrackAndTraceUrl: (url: string) => {
      set(() => {
        return {
          trackAndTraceUrl: url,
        };
      });
    },
    setTestUrl: (testUrl: string | undefined) => {
      set(() => {
        return {
          testUrl,
        };
      });
    },
    //New OI
    setAccordionRoute: (route: AccordionRoutesEnum, payload: PayloadModel) => {
      set((state: Store) => {
        //Add tracking
        Analytics.getInstance().trackStep(route.toString(), TrackingFlowTypeEnum.Vertical, payload)
        
        return {
          accordionRoute: route,
        }
      });
    },
    setCompletedAccordionRoute: (route: AccordionRoutesEnum) => {
      set((state: Store) => {

        const exist = state.accordionRouteCompletedArray.includes(route);

        return {
          accordionRouteCompletedArray: exist ? state.accordionRouteCompletedArray : [...state.accordionRouteCompletedArray, route],
        };
      });
    },
    setSwitchLayout: (visibleLayout: VisibleLayoutEnum) => {
      set(() => ({
        switchLayout: visibleLayout,
      }))
    },
    setFullAmountPayment: (fullAmountPayment: number) => {
      set(() => ({
        fullAmountPayment: fullAmountPayment,
      }));
    },
    setBsPaymentFee: (bsPaymentFee: number) => {
      set(() => ({
        bsPaymentFee: bsPaymentFee,
      }));
    },
    //New OI end
  } as FormEnrollmentSlice);
