import styled from "styled-components";
import { devices } from "../../FormEnrollmentNew.styles";
export const MainContentWrapper = styled.div`

`;

export const FirstRow = styled.div`
  margin-bottom: 25px;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${devices.laptop} {
    gap: 16px;
    flex-direction: row;
  }
`;

export const BottomContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 10px;
  @media ${devices.laptop} {
    gap: 16px;
    flex-direction: row;
  }
`;

export const SelfEmployeedContainer = styled.div`
  margin-bottom: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Headline = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 10px;
`;

export const HeadlineSelfemployeed = styled.p`
  font-size: var(--font-size-body);
  margin-bottom: 4px;
`;

export const RecieptContainer = styled.div`
  margin-top: 40px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
`;

export const SalaryInsuranceInfo = styled.p`
    margin-bottom: 24px;
    margin-top: 8px;
    font-size: var(--font-size-small);
`;
