import { ReactNode, useState } from "react";
import { Body, CheckContainer, Checked, ConsentCardWrapper, Container, Description, DescriptionContainer, Icon, IconContainer, InfoIcon, InfoIconContainer, Title, Unchecked } from "./ConsentCard.style";
import Modal from "../Modal";

interface Props {
    title: string,
    description?: string, 
    descriptionComponent?: ReactNode,
    isChecked: boolean,
    hasInfoIcon: boolean,
    onClick: () => void,
    modalText: string,
    modalTextH1: string,
    iconSrc: string,
}

export default function ConsentCard({title, description, isChecked, hasInfoIcon, onClick, modalText, modalTextH1, descriptionComponent, iconSrc} : Props) {
    const [showInfo, setShowInfo] = useState(false);

    return(
    <Container>
        <ConsentCardWrapper onClick={onClick} isChecked={isChecked}>
            <CheckContainer>
                <Unchecked>
                {isChecked && (
                    <Checked />
                )}
                </Unchecked>
            </CheckContainer>
            <Body>
                <IconContainer>
                    <Icon src={iconSrc} />
                </IconContainer>
                <DescriptionContainer>
                    <Title isChecked={isChecked}>{title}</Title>
                    {description && <Description dangerouslySetInnerHTML={{ __html: description }}></Description>}
                    {descriptionComponent && <Description>{descriptionComponent}</Description>}
                </DescriptionContainer>
            </Body>
            <InfoIconContainer>
                {hasInfoIcon && <InfoIcon onClick={() => setShowInfo(!showInfo)} />}
            </InfoIconContainer>
            <Modal modalText={modalText} modalTexth1={modalTextH1} modalBlock={false} showModal={showInfo} onClose={() => setShowInfo(false)} />   
        </ConsentCardWrapper>
    </Container>
    );  
}