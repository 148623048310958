import { useEffect, useRef, useState } from "react";
import { InputErrorMessage, Label, Select } from "./SelectVF.style";
import { UnemploymentInsuranceOptionModel } from "../../../../types/UnemploymentInsuranceOptionModel";
import { AccordionRoutesEnum } from "../../../../types/AccordionRoutes";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { useStore } from "../../../../store/store";

interface Props {
    id: string,
    name: string,
    options: UnemploymentInsuranceOptionModel[],
    defaultOption?: string,
    placeholder?: string,
    selectedOption: string | undefined,
    label?: string,
    step: AccordionRoutesEnum,
    validated?: boolean,
    onSelect: (id: string) => void;
}

export default function select({id, name, options, defaultOption, placeholder, selectedOption, label, step, validated = true, onSelect} :Props) {
    const windowSize = useWindowResize();
    const selecetRef = useRef<HTMLSelectElement>(null);
    const [selectWidth, setSelectWidth] = useState<number|undefined>(0);
    const accordionRoute = useStore.useAccordionRoute();

    useEffect(()=> {
        const width = selecetRef.current?.offsetWidth
        setSelectWidth(width);
    }, [windowSize]); 
    
    return (
        <>
            <Label htmlFor={id}>{label}</Label>
            <Select defaultValue={placeholder} 
                id={id} 
                name={name}
                ref={selecetRef}
                validated={validated}
                selectedOption={selectedOption}  
                onChange={(e) => {
                    onSelect(e.currentTarget.value);
                }} 
            >
                {placeholder && (
                    <option disabled>
                        {placeholder}
                    </option>
                )}
                {defaultOption && (
                    <option>
                        {defaultOption}
                    </option>
                )}
                {options.map((option, index) => (
                    <option key={index} value={option.id}>
                        {option.name}
                    </option>
                ))}   
            </Select>
            {(!validated && selectedOption === undefined) && (
                <InputErrorMessage>Hov, vil du kigge på denne igen?</InputErrorMessage>
            )}
        </>
    );
}